import React from "react";
import Modal from "@prism/modal";
import Col from "@prism/col";
import MultimediaViewer from "@fyusion/prism-viewer";
import ErrorBoundary from "../../common/ErrorBoundary";
import TakePhotoButton from "./capturePhoto/TakePhotoButton";
import DeleteDamageImageButton from "./capturePhoto/DeleteDamageImageButton";
import {REQUIRED_PHOTO_ITEMS} from "../../../utils/requiredPhotoItems";
import {connect} from "react-redux";

const isPhotoRequired = (damage) => {
    if (!!damage.damageImageRequired) {
        return damage.damageImageRequired === 'Y'
    }
    const longDamageKey = `${damage.itemCode}-${damage.subItemCode}-${damage.damageCode}-${damage.severityCode}`;
    return REQUIRED_PHOTO_ITEMS.includes(
        longDamageKey.split("-").slice(0, 4).join("-")
    );
};

const DamageImageModal = (props) => {
    const {showModal, toggle, imageTitle, damageKey, damage, getActiveImage} = props;

    const handleClose = () => {
        toggle();
    };

    return (
        <Modal isOpen={showModal} toggle={toggle} className="image-overlay">
            <Modal.Header toggle={toggle}/>
            <Modal.Body>
                <ErrorBoundary>
                    <MultimediaViewer
                        id="damage-image-modal-content"
                        hideAddOns={true}
                        key={props.globalDisplay.damageImageChecksum}
                        data={[
                            {
                                category: "DMG",
                                src: {
                                    thumbnail: "",
                                    lowRes: getActiveImage(damage.damageKey),
                                },
                                description: imageTitle,
                                damageKey: damageKey,
                            },
                        ]}
                    />
                </ErrorBoundary>
            </Modal.Body>
            <Modal.Footer style={{justifyContent: "center", padding: "0rem", marginTop: "0.5rem"}}>
                <Col id="image-modal-footer-content">
                    <Col>
                        <TakePhotoButton hasPhoto damageKey={damageKey} title={imageTitle}/>
                    </Col>
                    {!isPhotoRequired(damage) && (
                        <Col>
                            <DeleteDamageImageButton
                                style={{width: "100%"}}
                                damageKey={damageKey}
                                onConfirm={handleClose}
                            />
                        </Col>
                    )}
                </Col>
            </Modal.Footer>
        </Modal>
    );
};


function mapStateToProps({globalDisplay}) {
    return {globalDisplay};
}

export default connect(mapStateToProps, null)(DamageImageModal);
