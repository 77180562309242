import Api from "../../Api";
import csrf from "../../../csrf";
import {getChromeStyleId, getSubSeries} from "../../../utils/utils";
import {CRSO} from "../../../utils/constants";

export const fetchEstimate = async (data, damage) => {
    return await Api.fetchEstimate(buildPricingOrEstimateRequest(data, damage));
}

export const fetchPricing = async (data, damage) => {
    return await Api.fetchPricing(buildPricingOrEstimateRequest(data, damage))
}

const buildPricingOrEstimateRequest = (data, damage) => {
    let headers = csrf.getCSRFHeaders();
    headers['Content-Type'] = 'application/json';
    return {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            location: data.workOrderInfo.auctionCode,
            vin: data.unit?.vin,
            subSeries: getSubSeries(data.designatedDescription),
            chromeStyleId: getChromeStyleId(data.designatedDescription),
            groupCode: data.account.groupCode,
            accountNumber: data.consignment.manheimAccountNumber,
            categoryCode: data.consignment.categoryCode,
            consignmentHref: data.consignment.href,
            paintType: data.condition.paintType,
            damages: [damage]
        })
    };
}

export const buildDamage = (data, damageKey = null) => {
    return {
        damageKey,
        item: data.item,
        itemCode: data.itemCode,
        subItemCode: data.subItemCode,
        damageCode: data.damageCode,
        damage: data.damage,
        severityCode: data.severityCode,
        severity: data.severity,
        actionCode: data.actionCode,
        action: data.action,
        locationCode: data.locationCode,
        pricePlanIds: data.pricePlanIds,
        repairLaborHours: data.repairLaborHours,
        repairLaborCost: parseFloat(data.repairLaborCost),
        paintLaborHours: data.paintLaborHours,
        paintLaborCost: parseFloat(data.paintLaborCost),
        partLaborHours: data.partLaborHours,
        partLaborCost: parseFloat(data.partLaborCost),
        partCost: parseFloat(data.partCost),
        finalPartCost: parseFloat(data.finalPartCost)
    }
}

export const getDamageKey = (data, includeL1 = true) => {
    let damageKey = `${data.itemCode}-${data.subItemCode}-${data.damageCode}`
    return includeL1 ? `L1-${damageKey}` : damageKey;
};

export const getOverlays = async (estimateResponse) => {
    const overlays = [];
    if (Array.isArray(estimateResponse?.account)) {
        if (estimateResponse.account.length === 1) {
            overlays.push('account');
        }
    }
    if (Array.isArray(estimateResponse?.model)) {
        if (estimateResponse.model.length === 1) {
            overlays.push('model');
        }
    }
    if (Array.isArray(estimateResponse?.mitchell)) {
        if (estimateResponse.mitchell.length === 1) {
            overlays.push('mitchell');
        }
    }
    return overlays;
}

export const getPricingTypes = async (estimateResponse) => {
    const pricingTypes = [];
    if (Array.isArray(estimateResponse?.account) && estimateResponse.account.length > 1) {
        let m = new Map();
        m.set('Account', estimateResponse.account);
        pricingTypes.push(m)
    }
    if (Array.isArray(estimateResponse?.model) && estimateResponse.model.length > 1) {
        let m = new Map();
        m.set('Model', estimateResponse.model);
        pricingTypes.push(m)
    }
    if (Array.isArray(estimateResponse?.mitchell) && estimateResponse.mitchell.length > 1) {
        let m = new Map();
        m.set('Mitchell', estimateResponse.mitchell);
        pricingTypes.push(m)
    }
    return pricingTypes;
}

export const getPricePlans = async (estimateResponse) => {
    const pricePlans = [];
    if (!!estimateResponse.account?.length && estimateResponse.account.length === 1) {
        pricePlans.push({pricingType: 'account', pricePlanId: estimateResponse.account[0].id})
    }
    if (!!estimateResponse.model?.length && estimateResponse.model.length === 1) {
        pricePlans.push({pricingType: 'model', pricePlanId: estimateResponse.model[0].id})
    }
    if (!!estimateResponse.mitchell?.length && estimateResponse.mitchell.length === 1) {
        pricePlans.push({pricingType: 'mitchell', pricePlanId: estimateResponse.mitchell[0].id})
    }
    return pricePlans;
}

export const damageDoesNotNeedPricing = async (value) => {
    return value === '' || value === 'NA' || value === 'IC';
}

export const getDamageImageRequired = (partLaborCost, paintLaborCost, repairLaborCost, finalPartCost, damageImageRequired) => {
    const costIsAtLeast200 = Number(partLaborCost) + Number(paintLaborCost) + Number(repairLaborCost) + Number(finalPartCost) >= 200.00;
    return damageImageRequired === 'Y' || costIsAtLeast200 ? 'Y' : 'N'
}

export const getFinalPricing = async (pricingResponse) => {
    let damage = pricingResponse[0];
    const partLaborHours = damage.partLaborHours;
    const partLaborCost = getFinalCost(damage.partLaborCost);
    const paintLaborHours = damage.paintLaborHours;
    const paintLaborCost = getFinalCost(damage.paintLaborCost);
    const repairLaborHours = damage.repairLaborHours;
    const repairLaborCost = getFinalCost(damage.repairLaborCost);
    const partDescription = damage.partNumber;
    const partCost = getFinalCost(damage.partCost);
    const finalPartCost = getFinalCost(damage.finalPartCost);
    const damageImageRequired = getDamageImageRequired(partLaborCost, paintLaborCost, repairLaborCost, finalPartCost, damage.damageImageRequired);
    return {
        partLaborHours,
        partLaborCost,
        paintLaborHours,
        paintLaborCost,
        repairLaborHours,
        repairLaborCost,
        partDescription,
        partCost,
        finalPartCost,
        damageImageRequired
    };
}

export const getFinalCost = (cost) => {
    return (Math.round(cost * 100) / 100).toFixed(2);
}

export const getPricingErrors = async (isCRSO, state) => {
    let pricingErrors = {
        showPricingInputError: false,
        showEstimateError: false
    }
    switch (true) {
        case !isNaN(state.repairLaborCost) && parseFloat(state.repairLaborCost) > 0 :
        case !isNaN(state.paintLaborCost) && parseFloat(state.paintLaborCost) > 0 :
        case !isNaN(state.partLaborCost) && parseFloat(state.partLaborCost) > 0 :
        case !isNaN(state.partCost) && parseFloat(state.partCost) > 0 :
        case !isNaN(state.finalPartCost) && parseFloat(state.finalPartCost) > 0 :
            return pricingErrors;
        default:
            pricingErrors.showPricingInputError = true
            return pricingErrors;
    }
}

export const getPricingTypesAndOverlays = async (estimateResponse) => {
    const overlays = await getOverlays(estimateResponse);
    const pricingTypes = await getPricingTypes(estimateResponse);
    return {overlays, pricingTypes};
}

export const isCRSO = (condition) => {
    return condition?.conditionType?.toUpperCase() === CRSO;
}

export const getDamageForManualPricingInput = (pricingField, data, damageKey) => {
    let damage = buildDamage(data, damageKey);
    if (pricingField === 'repairLaborHours') {
        damage.repairLaborCost = null;
    }
    if (pricingField === 'paintLaborHours') {
        damage.paintLaborCost = null;
    }
    if (pricingField === 'partLaborHours') {
        damage.partLaborCost = null;
    }
    if (pricingField === 'partCost') {
        damage.finalPartCost = null;
    }
    return damage;
}
