import DataPair from "../../common/DataPair";
import Form from "@prism/form";
import React, {useEffect, useState} from "react";
import {ELECTRIC_ENGINE_CATEGORIES, PLEASE_SELECT} from "../../../utils/constants";
import Dropdown from "../../common/Dropdown";
import Api from "../../Api";

const CylinderCount = (props) => {
    const {
        designatedDescription,
        hasDDSResponse,
        onCylinderCountChange
    } = props

    const [options, setOptions] = useState([]);
    const fuelType = designatedDescription?.powertrain?.engine?.fuelCategory;
    const cylinderCount = designatedDescription?.powertrain?.engine?.cylinderCount;
    const isElectric = ELECTRIC_ENGINE_CATEGORIES.includes(fuelType);

    useEffect(() => {
        if (fuelType) {
            async function fetchData() {
                return await Api.getCylinderCounts();
            }

            if (!hasDDSResponse) {
                fetchData().then(r => setOptions(r.items?.length ? r.items : []));
            }
        }
    }, [fuelType, hasDDSResponse]);

    if ((!hasDDSResponse && !fuelType) || isElectric) {
        return <></>
    } else {
        return (
            hasDDSResponse ?
                <Form.Group>
                    <DataPair id="configure-cylinder-count" label="Cylinder Count">
                        {cylinderCount}
                    </DataPair>
                </Form.Group> :
                <Form.Group>
                    <DataPair id="configure-cylinder-count" label="Cylinder Count" required={true}>
                        <Dropdown disabled={!fuelType || !options?.length}
                                  onChange={(e) => onCylinderCountChange(options, e)}
                                  value={cylinderCount}
                                  id='cylinder-count-dropdown'>
                            {!cylinderCount && <option value=''>{PLEASE_SELECT}</option>}
                            {options?.map(option => {
                                return (
                                    <option
                                        value={option.name}
                                        key={option.name}>
                                        {option.name}
                                    </option>
                                )
                            })}
                        </Dropdown>
                    </DataPair>
                </Form.Group>
        )
    }

}

export default CylinderCount;