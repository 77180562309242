import React from "react";
import {handlePanelClick} from "../../../../../actions/flatCarActions";
import {connect} from "react-redux";
import FlatCarUtil from "../FlatCarUtil";

const TruckKingCabExterior = (props) => {
    const {handlePanelStriping, StripedPattern, SelectedStripedPattern, StripedPatternLegend, isSuggestedDamagesPresent} = FlatCarUtil();
    function handlePanelClick(panelId) {
        props.clearSelectedItem();
        props.handlePanelClick(panelId);
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -80 463.32 465">
            <StripedPattern/>
            <SelectedStripedPattern/>
            <StripedPatternLegend/>
            <g id="Layer_2" data-name="Layer 2" className="flat-car rotate">
                <g id="truck-king-cab-exterior">
                    <path id="Body" className="panel-dark"
                          d="M350,213.93h37.05s32.38,1.41,47.63-14.56c3.67-3.84,6.46-10.48,8.36-18.58.46-2,5.87-4,6.23-6.12a149.1,149.1,0,0,0-.07-47.19c-.41-2.39-5.89-4.69-6.43-6.87-1.9-7.59-4.59-13.8-8.09-17.46-15.25-16-47.63-14.54-47.63-14.54H350a2,2,0,0,1-.06-.23h-6.24c0,.07,0,.15.08.23h-62s-82,.29-83.16-.09a24.92,24.92,0,0,1-9.07,2.43c-25.59,1.52-93.54-.48-94.7.88-1.41,1.65-6.21,17.43-6.21,59.44s4.8,57.82,6.21,59.45c1.16,1.36,69.11-.63,94.7.89a24.92,24.92,0,0,1,9.07,2.43c1.17-.39,83.16-.08,83.16-.08l62,0a1.59,1.59,0,0,0-.07.2H350A1.42,1.42,0,0,1,350,213.93Z"/>
                    <path id="Spare_Tire" data-name="Spare Tire"
                          className={`panel-dark${handlePanelStriping(30)}`}
                          onClick={() => handlePanelClick(30)}
                          d="M53.48,151.62a26.74,26.74,0,1,0-26.74,26.74A26.74,26.74,0,0,0,53.48,151.62ZM36.81,165.44a17,17,0,0,1-7.42,3.06c-.07-.2-.66-2-1-3.15a6.17,6.17,0,0,1-.35-2.84c0-1.23.32-5.32.42-6.81,1.13,1,4.22,3.67,5.11,4.51a6.11,6.11,0,0,1,1.76,2.26C35.89,163.58,36.72,165.26,36.81,165.44Zm.78-5.26a6.19,6.19,0,0,1-2.25-1.76c-.85-.89-3.53-4-4.52-5.11,1.5-.11,5.58-.39,6.81-.42a6,6,0,0,1,2.84.36c1.19.36,3,1,3.16,1a17,17,0,0,1-3.07,7.41C40.37,161.59,38.7,160.76,37.59,160.18Zm-9.39-5H25.28l-2.07-2.06v-2.92l2.06-2.07H28.2l2.06,2.06v2.93ZM40.47,150a6,6,0,0,1-2.84.36c-1.23,0-5.31-.32-6.81-.42,1-1.13,3.67-4.22,4.52-5.11a6,6,0,0,1,2.26-1.76c1.1-.59,2.79-1.42,3-1.51A17,17,0,0,1,43.63,149C43.43,149,41.66,149.63,40.47,150Zm-5.17-9.23A6,6,0,0,1,33.54,143c-.89.84-4,3.53-5.11,4.52-.1-1.5-.39-5.58-.42-6.81a6,6,0,0,1,.36-2.84c.36-1.19,1-3,1-3.16a17,17,0,0,1,7.42,3.07C36.72,138,35.89,139.66,35.3,140.76Zm-10.19-2.87a6,6,0,0,1,.36,2.84c0,1.22-.31,5.31-.42,6.81-1.13-1-4.22-3.68-5.11-4.52a6,6,0,0,1-1.76-2.26c-.58-1.11-1.42-2.79-1.5-3a16.91,16.91,0,0,1,7.41-3.07C24.15,134.92,24.75,136.69,25.11,137.89Zm-9.23,5.17a6.14,6.14,0,0,1,2.27,1.76c.84.89,3.53,4,4.51,5.11-1.49.1-5.58.38-6.81.42A6.17,6.17,0,0,1,13,150c-1.19-.37-3-1-3.16-1a17,17,0,0,1,3.07-7.41C13.1,141.64,14.78,142.47,15.88,143.06ZM13,153.24a6.17,6.17,0,0,1,2.84-.35c1.23,0,5.31.31,6.81.42-1,1.13-3.67,4.22-4.52,5.11a6.09,6.09,0,0,1-2.25,1.76c-1.11.58-2.79,1.41-3,1.5a17,17,0,0,1-3.07-7.41C10.06,154.2,11.82,153.61,13,153.24Zm5.17,9.23a6,6,0,0,1,1.76-2.25c.89-.85,4-3.54,5.11-4.52.11,1.5.38,5.58.42,6.81a6.19,6.19,0,0,1-.35,2.84c-.37,1.19-1,3-1,3.15a17,17,0,0,1-7.42-3.06C16.77,165.24,17.6,163.57,18.18,162.47Z"/>
                    <path id="Left_Front_Wheel" data-name="Left Front Wheel"
                          className={`panel-dark${handlePanelStriping(8)}`}
                          onClick={() => handlePanelClick(8)}
                          d="M415.5,26.74a26.74,26.74,0,1,0-26.74,26.74A26.74,26.74,0,0,0,415.5,26.74ZM398.82,40.56a17,17,0,0,1-7.41,3.07c-.07-.2-.66-2-1-3.16a6,6,0,0,1-.35-2.84c0-1.23.31-5.32.42-6.81,1.13,1,4.22,3.67,5.11,4.52a6.25,6.25,0,0,1,1.76,2.26C397.9,38.7,398.73,40.38,398.82,40.56Zm.79-5.26a6,6,0,0,1-2.25-1.76c-.85-.89-3.54-4-4.52-5.11,1.5-.1,5.58-.39,6.81-.42a6,6,0,0,1,2.83.36c1.2.36,3,1,3.16,1a17,17,0,0,1-3.07,7.41C402.38,36.71,400.71,35.88,399.61,35.3Zm-9.39-5h-2.93l-2.06-2.06V25.28l2.06-2.06h2.92l2.07,2.06V28.2Zm12.27-5.15a6.21,6.21,0,0,1-2.85.35c-1.22,0-5.3-.31-6.8-.42,1-1.13,3.67-4.22,4.51-5.11a6.08,6.08,0,0,1,2.26-1.75c1.11-.6,2.8-1.43,3-1.51a17,17,0,0,1,3.06,7.4C405.45,24.15,403.68,24.75,402.49,25.12Zm-5.17-9.24a6.25,6.25,0,0,1-1.76,2.26c-.89.85-4,3.53-5.11,4.52-.11-1.49-.39-5.58-.42-6.81a5.93,5.93,0,0,1,.36-2.84c.36-1.19,1-3,1-3.16a17,17,0,0,1,7.41,3.07C398.73,13.1,397.9,14.78,397.32,15.88ZM387.13,13a6.05,6.05,0,0,1,.36,2.84c0,1.23-.32,5.31-.42,6.81-1.13-1-4.22-3.67-5.11-4.52a6,6,0,0,1-1.76-2.26c-.59-1.1-1.42-2.79-1.51-3a17,17,0,0,1,7.41-3.07C386.17,10,386.77,11.82,387.13,13Zm-9.23,5.17a6,6,0,0,1,2.26,1.76c.84.89,3.53,4,4.52,5.11-1.5.1-5.59.39-6.81.42a6.17,6.17,0,0,1-2.84-.35c-1.2-.37-3-1-3.16-1a17,17,0,0,1,3.07-7.42C375.11,16.76,376.79,17.6,377.9,18.18ZM375,28.36a6.17,6.17,0,0,1,2.84-.35c1.22,0,5.31.32,6.8.42-1,1.13-3.67,4.22-4.51,5.11a6,6,0,0,1-2.26,1.76c-1.1.58-2.78,1.41-3,1.51a17,17,0,0,1-3.07-7.42C372.07,29.32,373.84,28.73,375,28.36Zm5.17,9.24A6.11,6.11,0,0,1,382,35.34c.9-.85,4-3.54,5.12-4.52.1,1.5.38,5.58.42,6.81a6,6,0,0,1-.36,2.84c-.36,1.19-1,3-1,3.16a17,17,0,0,1-7.41-3.07C378.78,40.37,379.61,38.7,380.2,37.6Z"/>
                    <path id="Left_Rear_Wheel" data-name="Left Rear Wheel"
                          className={`panel-dark${handlePanelStriping(19)}`}
                          onClick={() => handlePanelClick(19)}
                          d="M197.84,26.74A26.74,26.74,0,1,0,171.1,53.48,26.74,26.74,0,0,0,197.84,26.74ZM181.16,40.56a17,17,0,0,1-7.41,3.07c-.07-.2-.66-2-1-3.16a6,6,0,0,1-.35-2.84c0-1.23.31-5.32.42-6.81,1.13,1,4.22,3.67,5.11,4.52a6.14,6.14,0,0,1,1.76,2.26C180.24,38.7,181.07,40.38,181.16,40.56ZM182,35.3a6,6,0,0,1-2.25-1.76c-.85-.89-3.54-4-4.52-5.11,1.5-.1,5.58-.39,6.81-.42a6,6,0,0,1,2.83.36c1.2.36,3,1,3.16,1a17,17,0,0,1-3.07,7.41C184.72,36.71,183.05,35.88,182,35.3Zm-9.39-5h-2.93l-2.06-2.06V25.28l2.06-2.06h2.92l2.07,2.06V28.2Zm12.27-5.15a6.21,6.21,0,0,1-2.85.35c-1.22,0-5.3-.31-6.8-.42,1-1.13,3.67-4.22,4.51-5.11A6,6,0,0,1,182,18.19c1.1-.6,2.79-1.43,3-1.51a16.9,16.9,0,0,1,3.06,7.4C187.79,24.15,186,24.75,184.83,25.12Zm-5.17-9.24a6.14,6.14,0,0,1-1.76,2.26c-.89.85-4,3.53-5.11,4.52-.11-1.49-.39-5.58-.42-6.81a5.93,5.93,0,0,1,.36-2.84c.36-1.19,1-3,1-3.16a17,17,0,0,1,7.41,3.07C181.08,13.1,180.24,14.78,179.66,15.88ZM169.47,13a6.05,6.05,0,0,1,.36,2.84c0,1.23-.32,5.31-.42,6.81-1.13-1-4.22-3.67-5.11-4.52a6,6,0,0,1-1.76-2.26c-.59-1.1-1.42-2.79-1.51-3a17,17,0,0,1,7.41-3.07C168.51,10,169.11,11.82,169.47,13Zm-9.23,5.17a6,6,0,0,1,2.26,1.76c.84.89,3.54,4,4.52,5.11-1.5.1-5.59.39-6.81.42a6.17,6.17,0,0,1-2.84-.35c-1.2-.37-3-1-3.16-1a17,17,0,0,1,3.07-7.42C157.45,16.76,159.14,17.6,160.24,18.18Zm-2.87,10.18a6.17,6.17,0,0,1,2.84-.35c1.22,0,5.31.32,6.81.42-1,1.13-3.68,4.22-4.52,5.11a6,6,0,0,1-2.26,1.76c-1.1.58-2.78,1.41-3,1.51a17,17,0,0,1-3.07-7.42C154.41,29.32,156.18,28.73,157.37,28.36Zm5.17,9.24a6.11,6.11,0,0,1,1.75-2.26c.9-.85,4-3.54,5.12-4.52.1,1.5.38,5.58.42,6.81a6,6,0,0,1-.36,2.84c-.36,1.19-1,3-1,3.16A17,17,0,0,1,161,40.56C161.12,40.37,162,38.7,162.54,37.6Z"/>
                    <path id="Right_Front_Wheel" data-name="Right Front Wheel"
                          className={`panel-dark${handlePanelStriping(32)}`}
                          onClick={() => handlePanelClick(32)}
                          d="M415.5,276.35a26.74,26.74,0,1,0-26.74,26.74A26.74,26.74,0,0,0,415.5,276.35Zm-16.68,13.82a17,17,0,0,1-7.41,3.07c-.06-.19-.66-2-1-3.16a5.91,5.91,0,0,1-.36-2.84c0-1.23.31-5.31.42-6.81,1.13,1,4.22,3.67,5.11,4.52a6.25,6.25,0,0,1,1.76,2.26C397.9,288.31,398.73,290,398.82,290.17Zm.79-5.27a6,6,0,0,1-2.26-1.75c-.84-.89-3.53-4-4.51-5.11,1.5-.11,5.58-.39,6.8-.42a6.21,6.21,0,0,1,2.85.35c1.19.37,3,1,3.15,1a17,17,0,0,1-3.06,7.4C402.4,286.33,400.72,285.5,399.61,284.9Zm-9.4-5h-2.92l-2.06-2.06v-2.92l2.06-2.06h2.93l2.06,2.07v2.92Zm12.27-5.16a6,6,0,0,1-2.83.36c-1.23,0-5.31-.32-6.81-.42,1-1.13,3.67-4.22,4.52-5.11a6,6,0,0,1,2.25-1.76c1.1-.58,2.77-1.41,3-1.51a17,17,0,0,1,3.07,7.41C405.46,273.76,403.68,274.36,402.48,274.72Zm-5.16-9.22a6.3,6.3,0,0,1-1.76,2.25c-.89.85-4,3.54-5.11,4.52-.11-1.49-.39-5.58-.42-6.81a6,6,0,0,1,.35-2.84c.37-1.19,1-3,1-3.16a17,17,0,0,1,7.41,3.07C398.73,262.71,397.9,264.39,397.32,265.5Zm-10.19-2.88a6,6,0,0,1,.36,2.84c0,1.23-.32,5.31-.42,6.81-1.13-1-4.22-3.67-5.12-4.52a6.16,6.16,0,0,1-1.75-2.25c-.59-1.11-1.42-2.78-1.51-3a17,17,0,0,1,7.41-3.07C386.17,259.66,386.77,261.43,387.13,262.62Zm-9.23,5.17a6,6,0,0,1,2.26,1.76c.84.89,3.53,4,4.51,5.11-1.49.1-5.58.39-6.8.42a6.17,6.17,0,0,1-2.84-.35c-1.19-.37-3-1-3.16-1a17,17,0,0,1,3.07-7.42C375.12,266.38,376.8,267.21,377.9,267.79ZM375,278a6.17,6.17,0,0,1,2.84-.35c1.22,0,5.31.32,6.81.42-1,1.13-3.68,4.22-4.52,5.11a6,6,0,0,1-2.26,1.76c-1.11.58-2.79,1.42-3,1.51a17,17,0,0,1-3.07-7.42C372.06,278.94,373.83,278.34,375,278Zm5.17,9.24A6,6,0,0,1,382,285c.89-.85,4-3.54,5.11-4.52.1,1.5.38,5.58.42,6.81a6,6,0,0,1-.36,2.84c-.36,1.19-1,3-1,3.16a17,17,0,0,1-7.41-3.07C378.78,290,379.61,288.31,380.2,287.21Z"/>
                    <path id="Right_Rear_Wheel" data-name="Right Rear Wheel"
                          className={`panel-dark${handlePanelStriping(43)}`}
                          onClick={() => handlePanelClick(43)}
                          d="M197.84,276.35a26.74,26.74,0,1,0-26.74,26.74A26.74,26.74,0,0,0,197.84,276.35Zm-16.68,13.82a17,17,0,0,1-7.41,3.07c-.06-.19-.66-2-1-3.16a5.91,5.91,0,0,1-.36-2.84c0-1.23.31-5.31.42-6.81,1.13,1,4.22,3.67,5.11,4.52a6.14,6.14,0,0,1,1.76,2.26C180.24,288.31,181.08,290,181.16,290.17Zm.8-5.27a5.93,5.93,0,0,1-2.27-1.75c-.84-.89-3.53-4-4.51-5.11,1.5-.11,5.58-.39,6.8-.42a6.21,6.21,0,0,1,2.85.35c1.19.37,3,1,3.15,1a16.9,16.9,0,0,1-3.06,7.4C184.75,286.33,183.06,285.5,182,284.9Zm-9.41-5h-2.92l-2.06-2.06v-2.92l2.06-2.06h2.93l2.06,2.07v2.92Zm12.27-5.16a6,6,0,0,1-2.83.36c-1.23,0-5.31-.32-6.81-.42,1-1.13,3.67-4.22,4.52-5.11a6,6,0,0,1,2.25-1.76c1.1-.58,2.77-1.41,3-1.51a17,17,0,0,1,3.07,7.41C187.8,273.76,186,274.36,184.82,274.72Zm-5.16-9.22a6.19,6.19,0,0,1-1.76,2.25c-.89.85-4,3.54-5.11,4.52-.11-1.49-.39-5.58-.42-6.81a6,6,0,0,1,.35-2.84c.37-1.19,1-3,1-3.16a17,17,0,0,1,7.41,3.07C181.07,262.71,180.24,264.39,179.66,265.5Zm-10.19-2.88a6,6,0,0,1,.36,2.84c0,1.23-.32,5.31-.42,6.81-1.13-1-4.22-3.67-5.12-4.52a6.16,6.16,0,0,1-1.75-2.25c-.59-1.11-1.42-2.78-1.51-3a17,17,0,0,1,7.41-3.07C168.51,259.66,169.11,261.43,169.47,262.62Zm-9.23,5.17a6,6,0,0,1,2.26,1.76c.84.89,3.53,4,4.52,5.11-1.5.1-5.59.39-6.81.42a6.17,6.17,0,0,1-2.84-.35c-1.19-.37-3-1-3.16-1a17,17,0,0,1,3.07-7.42C157.46,266.38,159.14,267.21,160.24,267.79ZM157.37,278a6.17,6.17,0,0,1,2.84-.35c1.22,0,5.31.32,6.81.42-1,1.13-3.68,4.22-4.52,5.11a6,6,0,0,1-2.26,1.76c-1.1.58-2.79,1.42-3,1.51a17,17,0,0,1-3.07-7.42C154.4,278.94,156.17,278.34,157.37,278Zm5.17,9.24A6,6,0,0,1,164.3,285c.89-.85,4-3.54,5.11-4.52.1,1.5.38,5.58.42,6.81a6,6,0,0,1-.36,2.84c-.36,1.19-1,3-1,3.16a17,17,0,0,1-7.41-3.07C161.12,290,162,288.31,162.54,287.21Z"/>
                    <path id="Front_Bumper" data-name="Front Bumper"
                          className={`panel-dark${handlePanelStriping(2)}`}
                          onClick={() => handlePanelClick(2)}
                          d="M434,95s13.27.52,17.74,6,11.63,23.32,11.63,50.8-6.55,42.75-11.4,49.4-18.2,7.44-18.2,7.44c8.93-7.28,12.23-21.9,12.23-21.9l4.66-4s4.79-11.67,4.79-30.94-4.83-31.17-4.83-31.17l-5-4.15S442.32,100.54,434,95Z"/>
                    <path id="Rear_Bumper" data-name="Rear Bumper"
                          className={`panel-dark${handlePanelStriping(29)}`}
                          onClick={() => handlePanelClick(29)}
                          d="M88.66,210.87s-8.72-.54-13.38-6.31-10.06-24.34-10.06-53S70,106.92,75,100,88.9,92.2,88.9,92.2a53,53,0,0,0-4.51,18.7H80.58s-5,20.52-5,40.64,5.05,40.88,5.05,40.88l4.22.15S83.82,200.87,88.66,210.87Z"/>
                    <path id="Hood" className={`panel-dark${handlePanelStriping(5)}`}
                          onClick={() => handlePanelClick(5)}
                          d="M415.5,201.37c2.94-5.15,10.49-16.25,8.61-26.62,3.38-4.25,11-5.5,15-10.5s4-21.38,0-27.13-11.5-5.62-15.62-10.75c1.62-7.37-4-19.76-7-25.58s-43.43.25-43.43.25,10.53,23.66,10.53,50.16-10.53,50.17-10.53,50.17S412.55,206.52,415.5,201.37Z"/>
                    <path id="Right_Front_Glass" data-name="Right Front Glass"
                          className={`panel-white${handlePanelStriping(66)}`}
                          onClick={() => handlePanelClick(66)}
                          d="M289.64,207.2c-2.59-2.5-5.78-6.77-8.36-13.91,33.28,1.69,67.06,7.39,73.86,13.12C355.43,206.67,328.93,207.3,289.64,207.2Z"/>
                    <path id="Right_Headlight" data-name="Right Headlight"
                          className={`panel-white${handlePanelStriping(4)}`}
                          onClick={() => handlePanelClick(4)}
                          d="M437.92,174.77c-.25,30.75-18,26.83-18,26.83s7.24-14.18,7.9-21.95C428.17,175.15,435.92,173.39,437.92,174.77Z"/>
                    <path id="Left_Headlight" data-name="Left Headlight"
                          className={`panel-white${handlePanelStriping(3)}`}
                          onClick={() => handlePanelClick(3)}
                          d="M437.92,127.77c-2,1.38-9.75-.38-10.12-4.87-.66-7.78-7.9-22-7.9-22S437.67,97,437.92,127.77Z"/>
                    <path id="Windshield" className={`panel-white${handlePanelStriping(1)}`}
                          onClick={() => handlePanelClick(1)}
                          d="M365.23,101s10.79,11.28,12,50.22c-1.18,39-12,50.23-12,50.23-11.74,2.81-43.67-11-43.67-11,8-17.6,7.51-39.2,7.51-39.2s.46-21.59-7.51-39.19C321.56,112.07,353.49,98.23,365.23,101Z"/>
                    <path id="Rear_Glass" data-name="Rear Glass"
                          className={`panel-white${handlePanelStriping(28)}`}
                          onClick={() => handlePanelClick(28)}
                          d="M206.8,207.65l-3.69-.85c-1.86,0-4.62-29.14-4.62-55.54s2.76-55.57,4.62-55.53l3.69-.85s-2.59,29.54-2.59,56.38S206.8,207.65,206.8,207.65Z"/>
                    <path id="Left_Front_Glass" data-name="Left Front Glass"
                          className={`panel-white${handlePanelStriping(65)}`}
                          onClick={() => handlePanelClick(65)}
                          d="M355.14,96.11c-6.79,5.73-40.56,11.45-73.86,13.13,2.59-7.16,5.79-11.42,8.38-13.9C328.93,95.24,355.43,95.86,355.14,96.11Z"/>
                    <g id="Left_Rear_Glass" data-name="Left Rear Glass" onClick={() => handlePanelClick(68)}>
                        <path className={`panel-white${handlePanelStriping(68)}`}
                              d="M220.69,95.05v13.17l-10.05-.35s-.58-10.59.83-12.56C213.88,95.22,217,95.13,220.69,95.05Z"/>
                        <path className={`panel-white${handlePanelStriping(68)}`}
                              d="M271.44,109.6c-4.45.13-32.2-.76-48.63-1.31V95c16.41-.32,41-.52,54.2-.6C273.68,99.44,272.13,106.19,271.44,109.6Z"/>
                    </g>
                    <path id="Left_Rear_Lights" data-name="Left Rear Lights"
                          className={`panel-white${handlePanelStriping(22)}`}
                          onClick={() => handlePanelClick(22)}
                          d="M98.65,97.37c3.75-3.75,9-2.4,9-2.4s-6.68,11.9-12.83,11.9C94.82,106.87,94.9,101.12,98.65,97.37Z"/>
                    <path id="Bed" className={`panel-white${handlePanelStriping(27)}`}
                          onClick={() => handlePanelClick(27)}
                          d="M102.23,187.74c-2.37-.62-2.1-72.94.23-73.69,8.68-2.82,11.36-12.14,12.64-12.11l70.12.15s2.67,24,2.67,49.17-2.67,49.18-2.67,49.18l-70.12.14C113.36,200.62,111.14,190.09,102.23,187.74Z"/>
                    <path id="Right_Rear_Lights" data-name="Right Rear Lights"
                          className={`panel-white${handlePanelStriping(21)}`}
                          onClick={() => handlePanelClick(21)}
                          d="M99,205.3c-3.75-3.75-3.84-9.5-3.84-9.5,6.15,0,12.84,11.9,12.84,11.9S102.74,209.05,99,205.3Z"/>
                    <g id="Right_Rear_Glass" data-name="Right Rear Glass" onClick={() => handlePanelClick(67)}>
                        <path className={`panel-white${handlePanelStriping(67)}`}
                              d="M220.69,194.31v13.18c-3.65-.08-6.81-.16-9.22-.26-1.41-2-.83-12.57-.83-12.57Z"/>
                        <path className={`panel-white${handlePanelStriping(67)}`}
                              d="M222.81,207.53V194.24c16.43-.55,44.18-1.44,48.63-1.32.69,3.41,2.25,10.19,5.57,15.23C263.82,208.05,239.22,207.86,222.81,207.53Z"/>
                    </g>
                    <path id="Left_Mirror" data-name="Left Mirror"
                          className={`panel-dark${handlePanelStriping(11)}`}
                          onClick={() => handlePanelClick(11)}
                          d="M351.18,83.71c-2.18-5.39-4.16-10.39-11-9.62,0,0,1.73,8.73,3.56,14.29H350C349.67,86.82,351.8,85.25,351.18,83.71Z"/>
                    <path id="Right_Mirror" data-name="Right Mirror"
                          className={`panel-dark${handlePanelStriping(35)}`}
                          onClick={() => handlePanelClick(35)}
                          d="M351.18,218.83c.63-1.55-1.54-3.12-1.21-4.7h-6.23c-1.83,5.56-3.57,14.32-3.57,14.32C347,229.22,349,224.22,351.18,218.83Z"/>
                    <polygon id="Left_Front_Door" data-name="Left Front Door"
                             className={`panel-dark${handlePanelStriping(10)}`}
                             onClick={() => handlePanelClick(10)}
                             points="285.83 27.55 350.02 27.55 361.42 71.69 285.83 71.42 285.83 27.55"/>
                    <path id="Left_Front_Fender" data-name="Left Front Fender"
                          className={`panel-dark${handlePanelStriping(7)}`}
                          onClick={() => handlePanelClick(7)}
                          d="M358.47,27.55c.42,17.24,13.55,29.74,31.3,29.74,19,0,29.25-15.6,29.34-30.34H446l3.41,33.27a3.84,3.84,0,0,1,0,.43c0,1.52-.35,6.54-4.81,7.36C432,70.32,402,71.66,362.44,71.69L351,27.55Z"/>
                    <g id="Left_Rear_Door" data-name="Left Rear Door" onClick={() => handlePanelClick(14)}>
                        <polygon className={`panel-dark${handlePanelStriping(14)}`}
                                 points="221.26 27.55 284.84 27.55 284.84 71.42 211.35 71.27 221.26 27.55"/>
                    </g>
                    <g id="Left_Bed_Side" data-name="Left Bed Side" onClick={() => handlePanelClick(16)}>
                        <path className={`panel-dark${handlePanelStriping(16)}`}
                              d="M95,57.67c0-15.23.44-22.84,4.42-26.78,4.32-4.27,12.78-4.19,29.65-4,3.58,0,7.53.07,11.89.08.11,23.62,19.62,29.87,30,29.87a35.41,35.41,0,0,0,13.84-3c12-5.73,16.75-18.8,17.09-26.32h18.4l-9.91,43.72-41.15-.09L95,70.94c0-2.59,0-5,0-7.33C95,61.52,95,59.54,95,57.67Z"/>
                    </g>
                    <path id="Left_Rocker" data-name="Left Rocker"
                          className={`panel-dark${handlePanelStriping(13)}`}
                          onClick={() => handlePanelClick(13)}
                          d="M201.87,26.56c.43-1.32,3.37-9.31,11.79-9.31,2.49,0,26.46.15,54.21.33,34.9.22,74.45.47,79.62.47,8.13,0,10.53,7.14,10.91,8.51H346.79v0l-.22-.05-.37.1H285.83l-.2,0h-.79v0Z"/>
                    <polygon id="Right_Front_Door" data-name="Right Front Door"
                             className={`panel-dark${handlePanelStriping(34)}`}
                             onClick={() => handlePanelClick(34)}
                             points="285.83 231.67 361.42 231.4 350.02 275.54 285.83 275.54 285.83 231.67"/>
                    <path id="Right_Front_Fender" data-name="Right Front Fender"
                          className={`panel-dark${handlePanelStriping(31)}`}
                          onClick={() => handlePanelClick(31)}
                          d="M362.44,231.4c39.59,0,69.54,1.37,82.18,3.68,4.46.82,4.81,5.84,4.81,7.36,0,.27,0,.43,0,.44L446,276.15H419.11c-.09-14.75-10.37-30.35-29.34-30.35-17.75,0-30.88,12.5-31.3,29.74H351Z"/>
                    <g id="Right_Rear_Door" data-name="Right Rear Door" onClick={() => handlePanelClick(38)}>
                        <polygon className={`panel-dark${handlePanelStriping(38)}`}
                                 points="284.84 231.67 284.84 275.54 221.26 275.54 211.35 231.82 284.84 231.67"/>
                    </g>
                    <g id="Right_Bed_Side" data-name="Right Bed Side" onClick={() => handlePanelClick(40)}>
                        <path className={`panel-dark${handlePanelStriping(40)}`}
                              d="M95,245.42c0-1.87,0-3.84,0-5.94,0-2.29,0-4.73,0-7.33l74.15-.24,41.15-.09,9.91,43.72h-18.4c-.34-7.52-5.14-20.59-17.09-26.32a35.41,35.41,0,0,0-13.84-2.95c-10.33,0-29.84,6.25-30,29.88-4.36,0-8.31,0-11.89.07-16.87.17-25.33.25-29.65-4C95.39,268.26,95,260.65,95,245.42Z"/>
                    </g>
                    <path id="Right_Rocker" data-name="Right Rocker"
                          className={`panel-dark${handlePanelStriping(37)}`}
                          onClick={() => handlePanelClick(37)}
                          d="M284.84,276.53l.2,0h.79v0h60.41l.51.14,0-.14H358.4c-.36,1.3-2.74,8.51-10.91,8.51-5.17,0-44.72.25-79.62.47-27.75.18-51.72.33-54.21.33-8.34,0-11.3-7.84-11.78-9.31Z"/>
                    <g id="Deck_Lid" data-name="Deck Lid" onClick={() => handlePanelClick(23)}
                       style={{transform: "translate(-2.5px, -5px)"}}>
                        <path className={`panel-dark${handlePanelStriping(23)}`}
                              d="M105,118.5c0,0-12.1-0.1-12.2,0v0.1c-0.7,7.7-1.5,19.5-1.6,34c0,1,0,2,0,3.1c0,1.2,0,2.3,0,3.5,c0,0.3,0,0.7,0,1c0,0.9,0,1.7,0,2.6c0,0.3,0,0.6,0,0.8c0,1.1,0,2.1,0.1,3.2c0,0.2,0,0.4,0,0.5c0,0.9,0,1.7,0.1,2.6,c0,0.3,0,0.6,0,0.9c0,0.8,0.1,1.6,0.1,2.4c0,0.2,0,0.4,0,0.6c0,0.9,0.1,1.9,0.1,2.8c0,0.2,0,0.4,0,0.7c0,0.7,0.1,1.4,0.1,2.1,c0,0.3,0,0.5,0,0.7c0,0.8,0.1,1.6,0.1,2.4v0.1c0.1,0.8,0.1,1.6,0.2,2.4c0,0.2,0,0.4,0,0.7c0,0.6,0.1,1.2,0.1,1.8c0,0.2,0,0.4,0,0.6,c0.1,0.7,0.1,1.5,0.2,2.2c0,0.1,0,0.2,0,0.3c0,0.4,0.1,0.9,0.1,1.3l12.1,0.4C102.4,191.5,102.7,119.2,105,118.5z"/>
                    </g>
                    <path id="Roof" className={`panel-dark${handlePanelStriping(6)}`}
                          onClick={() => handlePanelClick(6)}
                          d="M208.78,151.71c0-21.9,4.35-34.26,5.31-35.22a5.32,5.32,0,0,1,3.78-1.57s35.57,2.6,52.23,2.6c12.59,0,41.16-2.6,41.16-2.6a5.3,5.3,0,0,1,3.77,1.57c1,1,6.53,13.32,6.53,35.22S316,186,315,186.94a5.33,5.33,0,0,1-3.77,1.56s-25.41-2.59-41.16-2.59-52.23,2.59-52.23,2.59a5.35,5.35,0,0,1-3.78-1.56C213.13,186,208.78,173.62,208.78,151.71Z"/>
                </g>
            </g>
            {isSuggestedDamagesPresent() &&
                <>
                    <rect id="exterior-legend" x="0" y="350" width="5%" height="5%" fill="url(#suggested-legend)"></rect>
                    <text id="exterior-legend-text" x="23" y="366" fill="var(--charcoal)">Suggested Damage</text>
                </>
            }
        </svg>
    )
};

const matchDispatchToProps = {
    handlePanelClick
};

function mapStateToProps({globalDisplay}) {
    const {selectedFlatCarPanel} = globalDisplay;
    return {selectedFlatCarPanel};
}

export default connect(mapStateToProps, matchDispatchToProps)(TruckKingCabExterior)