import React from "react";
import {handlePanelClick} from "../../../../../actions/flatCarActions";
import {connect} from "react-redux";
import FlatCarUtil from "../FlatCarUtil";

const SUVExterior = (props) => {
    const {handlePanelStriping, StripedPattern, SelectedStripedPattern, StripedPatternLegend, isSuggestedDamagesPresent} = FlatCarUtil();
    function handlePanelClick(panelId) {
        props.clearSelectedItem();
        props.handlePanelClick(panelId);
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -80 463.32 455">
            <StripedPattern/>
            <SelectedStripedPattern/>
            <StripedPatternLegend/>
            <g id="Layer_2" data-name="Layer 2" className="flat-car rotate">
                <g id="suv-exterior">
                    <path id="Body" className="panel-dark"
                          d="M330,213.93h37.05s32.38,1.41,47.63-14.56c3.67-3.84,6.46-10.48,8.36-18.58.46-2,5.87-4,6.23-6.12a149.1,149.1,0,0,0-.07-47.19c-.41-2.39-5.89-4.69-6.43-6.87-1.9-7.59-4.59-13.8-8.09-17.46-15.25-16-47.63-14.54-47.63-14.54H330a.8.8,0,0,1,0-.21h-6.24a1.72,1.72,0,0,0,.07.21h-62l-34.16-.09S98,88.46,96.88,89.82C95.47,91.47,85,109.25,85,151.26s10.48,59.82,11.89,61.45C98,214.07,227.65,214,227.65,214l34.16-.08,62,0a1.87,1.87,0,0,0-.07.22H330C330,214.07,330,214,330,213.93Z"/>
                    <g id="Spare_Wheel" data-name="Spare Wheel" onClick={() => handlePanelClick(30)}>
                        <path id="Rim" className={`panel-dark${handlePanelStriping(30)}`}
                              d="M25.12,165.35c-.39,1.26-1.05,3.19-1.05,3.19a8.91,8.91,0,0,0,2.67.33,8.79,8.79,0,0,0,2.66-.33s-.65-1.93-1-3.19a6.17,6.17,0,0,1-.35-2.84c0-1.23.32-5.32.42-6.81,1.13,1,4.22,3.67,5.11,4.51a6.11,6.11,0,0,1,1.76,2.26c.62,1.17,1.52,3,1.52,3a8.54,8.54,0,0,0,2.12-1.65,8.76,8.76,0,0,0,1.65-2.12s-1.83-.91-3-1.52a6.19,6.19,0,0,1-2.25-1.76c-.85-.89-3.53-4-4.52-5.11,1.5-.11,5.58-.39,6.81-.42a6,6,0,0,1,2.84.36c1.26.38,3.19,1,3.19,1a8.67,8.67,0,0,0,.34-2.67,8.91,8.91,0,0,0-.33-2.67s-1.94.66-3.2,1a6,6,0,0,1-2.84.36c-1.23,0-5.31-.32-6.81-.42,1-1.13,3.67-4.22,4.52-5.11a6,6,0,0,1,2.26-1.76c1.16-.62,3-1.52,3-1.52a8.54,8.54,0,0,0-1.65-2.12,8.79,8.79,0,0,0-2.12-1.66s-.9,1.84-1.52,3A6,6,0,0,1,33.54,143c-.89.84-4,3.53-5.11,4.52-.1-1.5-.39-5.58-.42-6.81a6,6,0,0,1,.36-2.84c.38-1.26,1-3.2,1-3.2a8.91,8.91,0,0,0-2.67-.33,8.91,8.91,0,0,0-2.67.33s.66,1.94,1,3.2a6,6,0,0,1,.36,2.84c0,1.22-.31,5.31-.42,6.81-1.13-1-4.22-3.68-5.11-4.52a6,6,0,0,1-1.76-2.26c-.62-1.16-1.52-3-1.52-3a8.87,8.87,0,0,0-2.12,1.65,8.67,8.67,0,0,0-1.65,2.13s1.83.9,3,1.52a6.14,6.14,0,0,1,2.27,1.76c.84.89,3.53,4,4.51,5.11-1.49.1-5.58.38-6.81.42A6.17,6.17,0,0,1,13,150c-1.26-.39-3.2-1.05-3.2-1.05a8.91,8.91,0,0,0-.33,2.67,8.79,8.79,0,0,0,.33,2.66s1.94-.65,3.2-1a6.17,6.17,0,0,1,2.84-.35c1.23,0,5.31.31,6.81.42-1,1.13-3.67,4.22-4.52,5.11a6.09,6.09,0,0,1-2.25,1.76c-1.17.61-3,1.52-3,1.52a8.54,8.54,0,0,0,1.65,2.12,8.63,8.63,0,0,0,2.11,1.65s.91-1.83,1.53-3a6,6,0,0,1,1.76-2.25c.89-.85,4-3.54,5.11-4.52.11,1.5.38,5.58.42,6.81A6.19,6.19,0,0,1,25.12,165.35Zm-1.91-12.27v-2.92l2.06-2.07H28.2l2.06,2.06v2.93l-2.07,2.06H25.28Z"/>
                        <path id="Tire" className={`panel-dark${handlePanelStriping(30)}`}
                              d="M53.48,151.62a26.74,26.74,0,1,0-26.74,26.74A26.74,26.74,0,0,0,53.48,151.62Zm-43.85,0a17.11,17.11,0,1,1,17.11,17.11A17.12,17.12,0,0,1,9.63,151.62Z"/>
                    </g>
                    <path id="Left_Front_Wheel" data-name="Left Front Wheel"
                          className={`panel-dark${handlePanelStriping(8)}`} data-panel-id="4"
                          onClick={() => handlePanelClick(8)}
                          d="M395.5,26.74a26.74,26.74,0,1,0-26.74,26.74A26.74,26.74,0,0,0,395.5,26.74ZM378.82,40.56a17,17,0,0,1-7.41,3.07c-.07-.2-.66-2-1-3.16a6,6,0,0,1-.35-2.84c0-1.23.31-5.32.42-6.81,1.13,1,4.22,3.67,5.11,4.52a6.25,6.25,0,0,1,1.76,2.26C377.9,38.7,378.73,40.38,378.82,40.56Zm.79-5.26a6,6,0,0,1-2.25-1.76c-.85-.89-3.54-4-4.52-5.11,1.5-.1,5.58-.39,6.81-.42a6,6,0,0,1,2.83.36c1.2.36,3,1,3.16,1a17,17,0,0,1-3.07,7.41C382.38,36.71,380.71,35.88,379.61,35.3Zm-9.39-5h-2.93l-2.06-2.06V25.28l2.06-2.06h2.92l2.07,2.06V28.2Zm12.27-5.15a6.21,6.21,0,0,1-2.85.35c-1.22,0-5.3-.31-6.8-.42,1-1.13,3.67-4.22,4.51-5.11a6.08,6.08,0,0,1,2.26-1.75c1.11-.6,2.8-1.43,3-1.51a16.9,16.9,0,0,1,3.06,7.4C385.45,24.15,383.68,24.75,382.49,25.12Zm-5.17-9.24a6.25,6.25,0,0,1-1.76,2.26c-.89.85-4,3.53-5.11,4.52-.11-1.49-.39-5.58-.42-6.81a5.93,5.93,0,0,1,.36-2.84c.36-1.19,1-3,1-3.16a17,17,0,0,1,7.41,3.07C378.73,13.1,377.9,14.78,377.32,15.88ZM367.13,13a6.05,6.05,0,0,1,.36,2.84c0,1.23-.32,5.31-.42,6.81-1.13-1-4.22-3.67-5.11-4.52a6,6,0,0,1-1.76-2.26c-.59-1.1-1.42-2.79-1.51-3a17,17,0,0,1,7.41-3.07C366.17,10,366.77,11.82,367.13,13Zm-9.23,5.17a6,6,0,0,1,2.26,1.76c.84.89,3.53,4,4.52,5.11-1.5.1-5.59.39-6.81.42a6.17,6.17,0,0,1-2.84-.35c-1.2-.37-3-1-3.16-1a17,17,0,0,1,3.07-7.42C355.11,16.76,356.79,17.6,357.9,18.18ZM355,28.36a6.17,6.17,0,0,1,2.84-.35c1.22,0,5.31.32,6.8.42-1,1.13-3.67,4.22-4.51,5.11a6,6,0,0,1-2.26,1.76c-1.1.58-2.78,1.41-3,1.51a17,17,0,0,1-3.07-7.42C352.07,29.32,353.84,28.73,355,28.36Zm5.17,9.24A6.11,6.11,0,0,1,362,35.34c.9-.85,4-3.54,5.12-4.52.1,1.5.38,5.58.42,6.81a6,6,0,0,1-.36,2.84c-.36,1.19-1,3-1,3.16a17,17,0,0,1-7.41-3.07C358.78,40.37,359.61,38.7,360.2,37.6Z"/>
                    <path id="Left_Rear_Wheel" data-name="Left Rear Wheel"
                          className={`panel-dark${handlePanelStriping(19)}`}
                          onClick={() => handlePanelClick(19)}
                          d="M184.84,26.74A26.74,26.74,0,1,0,158.1,53.48,26.74,26.74,0,0,0,184.84,26.74ZM168.16,40.56a17,17,0,0,1-7.41,3.07c-.07-.2-.66-2-1-3.16a6,6,0,0,1-.35-2.84c0-1.23.31-5.32.42-6.81,1.13,1,4.22,3.67,5.11,4.52a6.14,6.14,0,0,1,1.76,2.26C167.24,38.7,168.07,40.38,168.16,40.56ZM169,35.3a6,6,0,0,1-2.25-1.76c-.85-.89-3.54-4-4.52-5.11,1.5-.1,5.58-.39,6.81-.42a6,6,0,0,1,2.83.36c1.2.36,3,1,3.16,1a17,17,0,0,1-3.07,7.41C171.72,36.71,170.05,35.88,169,35.3Zm-9.39-5h-2.93l-2.06-2.06V25.28l2.06-2.06h2.92l2.07,2.06V28.2Zm12.27-5.15a6.21,6.21,0,0,1-2.85.35c-1.22,0-5.3-.31-6.8-.42,1-1.13,3.67-4.22,4.51-5.11A6,6,0,0,1,169,18.19c1.1-.6,2.79-1.43,3-1.51a16.9,16.9,0,0,1,3.06,7.4C174.79,24.15,173,24.75,171.83,25.12Zm-5.17-9.24a6.14,6.14,0,0,1-1.76,2.26c-.89.85-4,3.53-5.11,4.52-.11-1.49-.39-5.58-.42-6.81a5.93,5.93,0,0,1,.36-2.84c.36-1.19,1-3,1-3.16a17,17,0,0,1,7.41,3.07C168.08,13.1,167.24,14.78,166.66,15.88ZM156.47,13a6.05,6.05,0,0,1,.36,2.84c0,1.23-.32,5.31-.42,6.81-1.13-1-4.22-3.67-5.11-4.52a6,6,0,0,1-1.76-2.26c-.59-1.1-1.42-2.79-1.51-3a17,17,0,0,1,7.41-3.07C155.51,10,156.11,11.82,156.47,13Zm-9.23,5.17a6,6,0,0,1,2.26,1.76c.84.89,3.54,4,4.52,5.11-1.5.1-5.59.39-6.81.42a6.17,6.17,0,0,1-2.84-.35c-1.2-.37-3-1-3.16-1a17,17,0,0,1,3.07-7.42C144.45,16.76,146.14,17.6,147.24,18.18Zm-2.87,10.18a6.17,6.17,0,0,1,2.84-.35c1.22,0,5.31.32,6.81.42-1,1.13-3.68,4.22-4.52,5.11a6,6,0,0,1-2.26,1.76c-1.1.58-2.78,1.41-3,1.51a17,17,0,0,1-3.07-7.42C141.41,29.32,143.18,28.73,144.37,28.36Zm5.17,9.24a6.11,6.11,0,0,1,1.75-2.26c.9-.85,4-3.54,5.12-4.52.1,1.5.38,5.58.42,6.81a6,6,0,0,1-.36,2.84c-.36,1.19-1,3-1,3.16A17,17,0,0,1,148,40.56C148.12,40.37,149,38.7,149.54,37.6Z"/>
                    <path id="Right_Front_Wheel" data-name="Right Front Wheel"
                          className={`panel-dark${handlePanelStriping(32)}`}
                          onClick={() => handlePanelClick(32)}
                          d="M395.5,276.75a26.74,26.74,0,1,0-26.74,26.74A26.74,26.74,0,0,0,395.5,276.75Zm-16.68,13.82a17,17,0,0,1-7.41,3.07c-.06-.19-.66-2-1-3.16a5.93,5.93,0,0,1-.36-2.84c0-1.23.31-5.31.42-6.81,1.13,1,4.22,3.67,5.11,4.52a6.25,6.25,0,0,1,1.76,2.26C377.9,288.71,378.73,290.39,378.82,290.57Zm.79-5.27a6,6,0,0,1-2.26-1.75c-.84-.89-3.53-4-4.51-5.11,1.5-.11,5.58-.39,6.8-.42a6.21,6.21,0,0,1,2.85.35c1.19.37,3,1,3.15,1a16.9,16.9,0,0,1-3.06,7.4C382.41,286.73,380.72,285.9,379.61,285.3Zm-9.4-5h-2.92l-2.06-2.06v-2.92l2.06-2.06h2.93l2.06,2.07v2.92Zm12.27-5.16a6,6,0,0,1-2.83.36c-1.23,0-5.31-.32-6.81-.42,1-1.13,3.67-4.22,4.52-5.11a6,6,0,0,1,2.25-1.76c1.1-.58,2.77-1.41,3-1.51a17,17,0,0,1,3.07,7.41C385.46,274.16,383.68,274.76,382.48,275.12Zm-5.16-9.23a6.25,6.25,0,0,1-1.76,2.26c-.89.85-4,3.54-5.11,4.52-.11-1.49-.39-5.58-.42-6.81a6,6,0,0,1,.35-2.84c.37-1.19,1-3,1-3.16a17,17,0,0,1,7.41,3.07C378.73,263.11,377.9,264.79,377.32,265.89ZM367.13,263a6,6,0,0,1,.36,2.84c0,1.23-.32,5.31-.42,6.81-1.13-1-4.22-3.67-5.12-4.52a6.11,6.11,0,0,1-1.75-2.26c-.59-1.1-1.42-2.77-1.51-3a17,17,0,0,1,7.41-3.07C366.17,260.06,366.77,261.83,367.13,263Zm-9.23,5.17a6,6,0,0,1,2.26,1.76c.84.89,3.53,4,4.51,5.11-1.49.1-5.58.39-6.8.42a6.17,6.17,0,0,1-2.84-.35c-1.19-.37-3-1-3.16-1a17,17,0,0,1,3.07-7.42C355.12,266.78,356.8,267.61,357.9,268.19ZM355,278.37a6.17,6.17,0,0,1,2.84-.35c1.22,0,5.31.32,6.81.42-1,1.13-3.68,4.22-4.52,5.11a6,6,0,0,1-2.26,1.76c-1.11.58-2.79,1.42-3,1.51a17,17,0,0,1-3.07-7.42C352.06,279.34,353.83,278.74,355,278.37Zm5.17,9.24a6,6,0,0,1,1.76-2.26c.89-.85,4-3.54,5.11-4.52.1,1.5.38,5.58.42,6.81a6.05,6.05,0,0,1-.36,2.84c-.36,1.19-1,3-1,3.16a17,17,0,0,1-7.41-3.07C358.78,290.4,359.61,288.71,360.2,287.61Z"/>
                    <path id="Right_Rear_Wheel" data-name="Right Rear Wheel"
                          className={`panel-dark${handlePanelStriping(43)}`}
                          onClick={() => handlePanelClick(43)}
                          d="M184.84,276.75a26.74,26.74,0,1,0-26.74,26.74A26.74,26.74,0,0,0,184.84,276.75Zm-16.68,13.82a17,17,0,0,1-7.41,3.07c-.06-.19-.66-2-1-3.16a5.93,5.93,0,0,1-.36-2.84c0-1.23.31-5.31.42-6.81,1.13,1,4.22,3.67,5.11,4.52a6.14,6.14,0,0,1,1.76,2.26C167.24,288.71,168.08,290.39,168.16,290.57Zm.8-5.27a5.93,5.93,0,0,1-2.27-1.75c-.84-.89-3.53-4-4.51-5.11,1.5-.11,5.58-.39,6.8-.42a6.21,6.21,0,0,1,2.85.35c1.19.37,3,1,3.15,1a16.9,16.9,0,0,1-3.06,7.4C171.75,286.73,170.06,285.9,169,285.3Zm-9.41-5h-2.92l-2.06-2.06v-2.92l2.06-2.06h2.93l2.06,2.07v2.92Zm12.27-5.16a6,6,0,0,1-2.83.36c-1.23,0-5.31-.32-6.81-.42,1-1.13,3.67-4.22,4.52-5.11a6,6,0,0,1,2.25-1.76c1.1-.58,2.77-1.41,3-1.51a17,17,0,0,1,3.07,7.41C174.8,274.16,173,274.76,171.82,275.12Zm-5.16-9.23a6.14,6.14,0,0,1-1.76,2.26c-.89.85-4,3.54-5.11,4.52-.11-1.49-.39-5.58-.42-6.81a6,6,0,0,1,.35-2.84c.37-1.19,1-3,1-3.16a17,17,0,0,1,7.41,3.07C168.07,263.11,167.24,264.79,166.66,265.89ZM156.47,263a6,6,0,0,1,.36,2.84c0,1.23-.32,5.31-.42,6.81-1.13-1-4.22-3.67-5.12-4.52a6.11,6.11,0,0,1-1.75-2.26c-.59-1.1-1.42-2.77-1.51-3a17,17,0,0,1,7.41-3.07C155.51,260.06,156.11,261.83,156.47,263Zm-9.23,5.17A6,6,0,0,1,149.5,270c.84.89,3.53,4,4.52,5.11-1.5.1-5.59.39-6.81.42a6.17,6.17,0,0,1-2.84-.35c-1.19-.37-3-1-3.16-1a17,17,0,0,1,3.07-7.42C144.46,266.78,146.14,267.61,147.24,268.19Zm-2.87,10.18a6.17,6.17,0,0,1,2.84-.35c1.22,0,5.31.32,6.81.42-1,1.13-3.68,4.22-4.52,5.11a6,6,0,0,1-2.26,1.76c-1.1.58-2.79,1.42-3,1.51a17,17,0,0,1-3.07-7.42C141.4,279.34,143.17,278.74,144.37,278.37Zm5.17,9.24a6,6,0,0,1,1.76-2.26c.89-.85,4-3.54,5.11-4.52.1,1.5.38,5.58.42,6.81a6.05,6.05,0,0,1-.36,2.84c-.36,1.19-1,3-1,3.16a17,17,0,0,1-7.41-3.07C148.12,290.4,149,288.71,149.54,287.61Z"/>
                    <path id="Front_Bumper" data-name="Front Bumper"
                          className={`panel-dark${handlePanelStriping(2)}`}
                          onClick={() => handlePanelClick(2)}
                          d="M414,95s13.27.52,17.74,6,11.63,23.32,11.63,50.8-6.55,42.75-11.4,49.4-18.2,7.44-18.2,7.44c8.93-7.28,12.23-21.9,12.23-21.9l4.66-4s4.79-11.67,4.79-30.94-4.83-31.17-4.83-31.17l-5-4.15S422.32,100.54,414,95Z"/>
                    <path id="Rear_Bumper" data-name="Rear Bumper"
                          className={`panel-dark${handlePanelStriping(29)}`}
                          onClick={() => handlePanelClick(29)}
                          d="M86.66,210.87s-8.72-.54-13.38-6.31-10.06-24.34-10.06-53S68,106.92,73,100,86.9,92.2,86.9,92.2a53,53,0,0,0-4.51,18.7H78.58s-5,20.52-5,40.64,5.05,40.88,5.05,40.88l4.22.15S81.82,200.87,86.66,210.87Z"/>
                    <path id="Hood" className={`panel-dark${handlePanelStriping(5)}`}
                          onClick={() => handlePanelClick(5)}
                          d="M395.5,201.37c2.94-5.15,10.49-16.25,8.61-26.62,3.38-4.25,11-5.5,15-10.5s4-21.38,0-27.13-11.5-5.62-15.62-10.75c1.62-7.37-4-19.76-7-25.58s-43.43.25-43.43.25,10.53,23.66,10.53,50.16-10.53,50.17-10.53,50.17S392.55,206.52,395.5,201.37Z"/>
                    <path id="Right_Front_Glass" data-name="Right Front Glass"
                          className={`panel-white${handlePanelStriping(66)}`}
                          onClick={() => handlePanelClick(66)}
                          d="M269.64,207.2c-2.59-2.5-5.78-6.77-8.36-13.91,33.28,1.69,67.06,7.39,73.86,13.12C335.43,206.67,308.93,207.3,269.64,207.2Z"/>
                    <path id="Right_Headlight" data-name="Right Headlight"
                          className={`panel-white${handlePanelStriping(4)}`}
                          onClick={() => handlePanelClick(4)}
                          d="M417.92,174.77c-.25,30.75-18,26.83-18,26.83s7.24-14.18,7.9-21.95C408.18,175.15,415.92,173.39,417.92,174.77Z"/>
                    <path id="Left_Headlight" data-name="Left Headlight"
                          className={`panel-white${handlePanelStriping(3)}`}
                          onClick={() => handlePanelClick(3)}
                          d="M417.92,127.77c-2,1.38-9.74-.38-10.12-4.87-.66-7.78-7.9-22-7.9-22S417.67,97,417.92,127.77Z"/>
                    <path id="Windshield" className={`panel-white${handlePanelStriping(1)}`}
                          onClick={() => handlePanelClick(1)}
                          d="M345.23,101s10.79,11.28,12,50.22c-1.18,39-12,50.23-12,50.23-11.74,2.81-43.67-11-43.67-11,8-17.6,7.51-39.2,7.51-39.2s.46-21.59-7.51-39.19C301.56,112.07,333.49,98.23,345.23,101Z"/>
                    <path id="Left_Front_Glass" data-name="Left Front Glass"
                          className={`panel-white${handlePanelStriping(65)}`}
                          onClick={() => handlePanelClick(65)}
                          d="M335.14,96.11c-6.79,5.73-40.56,11.45-73.86,13.13,2.59-7.16,5.79-11.42,8.38-13.9C308.93,95.24,335.43,95.86,335.14,96.11Z"/>
                    <path id="Left_Side_Glass" data-name="Left Side Glass"
                          className={`panel-white${handlePanelStriping(68)}`}
                          onClick={() => handlePanelClick(68)}
                          d="M257,94.4c-2.67,4-4.19,9.16-5,12.8a3.18,3.18,0,0,1-3,2.46c-11.46.27-39.06,1-46.42,1.19-.71,0-2.68,0-3.56-1.13s-5.14-8.07-4.95-15.41C207.21,93.79,240.16,94.51,257,94.4Z"/>
                    <path id="Left_Rear_Glass" data-name="Left Rear Glass"
                          className={`panel-white${handlePanelStriping(18)}`}
                          onClick={() => handlePanelClick(18)}
                          d="M181.23,109.35a2.38,2.38,0,0,1-2.22,1.86,147.58,147.58,0,0,1-44.87-5.34s-12.74-7.59-11.33-9.56c13.22-.52,41.17-1.8,58-1.91C182.74,102.09,182,106.21,181.23,109.35Z"/>
                    <path id="Left_Rear_Lights" data-name="Left Rear Lights"
                          className={`panel-white${handlePanelStriping(22)}`}
                          onClick={() => handlePanelClick(22)}
                          d="M100.65,97.37c3.75-3.75,9-2.4,9-2.4s-7.68,21.9-13.83,21.9C95.82,116.87,96.9,101.12,100.65,97.37Z"/>
                    <path id="Rear_Glass" data-name="Rear Glass"
                          className={`panel-white${handlePanelStriping(28)}`}
                          onClick={() => handlePanelClick(28)}
                          d="M112.11,103.73l22.69,11.15s-7.59,9.54-7.59,36.38,7.59,37.39,7.59,37.39L112.11,199.8c-1.86,0-11.62-22.14-11.62-48.54S110.25,103.69,112.11,103.73Z"/>
                    <path id="Right_Rear_Lights" data-name="Right Rear Lights"
                          className={`panel-white${handlePanelStriping(21)}`}
                          onClick={() => handlePanelClick(21)}
                          d="M101,205.3c-3.75-3.75-4.84-19.5-4.84-19.5,6.15,0,13.84,21.9,13.84,21.9S104.74,209.05,101,205.3Z"/>
                    <path id="Right_Rear_Glass" data-name="Right Rear Glass"
                          className={`panel-white${handlePanelStriping(42)}`}
                          onClick={() => handlePanelClick(42)}
                          d="M122.81,206.71c-1.41-2,11.33-9.56,11.33-9.56A147.58,147.58,0,0,1,179,191.81a2.38,2.38,0,0,1,2.22,1.86c.72,3.14,1.51,7.26-.39,15C164,208.51,136,207.23,122.81,206.71Z"/>
                    <path id="Right_Side_Glass" data-name="Right Side Glass"
                          className={`panel-white${handlePanelStriping(67)}`}
                          onClick={() => handlePanelClick(67)}
                          d="M194,208.71a26.64,26.64,0,0,1,4.95-15.41,4.16,4.16,0,0,1,3.56-1.13c7.36.15,35,.92,46.42,1.19a3.18,3.18,0,0,1,3,2.46c.86,3.64,2.38,8.77,5,12.81C240.16,208.51,207.21,209.23,194,208.71Z"/>
                    <path id="Left_Mirror" data-name="Left Mirror"
                          className={`panel-dark${handlePanelStriping(11)}`}
                          onClick={() => handlePanelClick(11)}
                          d="M331.18,83.71c-2.18-5.39-4.16-10.39-11-9.62,0,0,1.73,8.75,3.57,14.31H330C329.66,86.83,331.8,85.26,331.18,83.71Z"/>
                    <path id="Right_Mirror" data-name="Right Mirror"
                          className={`panel-dark${handlePanelStriping(35)}`}
                          onClick={() => handlePanelClick(35)}
                          d="M331.18,218.83c.62-1.55-1.52-3.11-1.22-4.68h-6.22c-1.84,5.56-3.57,14.3-3.57,14.3C327,229.22,329,224.22,331.18,218.83Z"/>
                    <polygon id="Left_Front_Door" data-name="Left Front Door"
                             className={`panel-dark${handlePanelStriping(10)}`}
                             onClick={() => handlePanelClick(10)}
                             points="265.83 27.55 330.02 27.55 341.42 71.69 265.83 71.42 265.83 27.55"/>
                    <path id="Left_Front_Fender" data-name="Left Front Fender"
                          className={`panel-dark${handlePanelStriping(7)}`}
                          onClick={() => handlePanelClick(7)}
                          d="M338.47,27.55c.42,17.24,13.55,29.74,31.3,29.74,19,0,29.25-15.6,29.34-30.34h7.64A21.28,21.28,0,0,1,428,46.1l1.44,14.12s0,.16,0,.43c0,1.52-.34,6.54-4.8,7.36C412,70.32,382,71.66,342.44,71.69L331,27.55Z"/>
                    <g id="Left_Rear_Qtr_Panel" data-name="Left Rear Qtr Panel" onClick={() => handlePanelClick(17)}>
                        <path className={`panel-dark${handlePanelStriping(17)}`}
                              d="M98,70.94c.76-3.29,1.45-6.34,2.09-9.15,8-35,8-35,24.46-34.86h3.33c.11,23.63,19.62,29.88,30,29.88a35.41,35.41,0,0,0,13.84-3c12-5.73,16.75-18.8,17.09-26.32h3.54c-.74,5.06-2,10.27-3.23,15.79-2.08,8.94-4.24,18.19-4.58,27.92l-35.4-.07Z"/>
                    </g>
                    <g id="Left_Rear_Door" data-name="Left Rear Door" onClick={() => handlePanelClick(14)}>
                        <path className={`panel-dark${handlePanelStriping(14)}`}
                              d="M185.51,71.26c.31-8.78,2.33-17.58,4.28-26.1,1.32-5.74,2.68-11.67,3.53-17.61h71.52V71.42Z"/>
                    </g>
                    <path id="Left_Rocker" data-name="Left Rocker"
                          className={`panel-dark${handlePanelStriping(13)}`}
                          onClick={() => handlePanelClick(13)}
                          d="M188.88,26.56c.45-1.4,3.4-9.31,11.77-9.31,2.5,0,25.14.15,51.35.33,33,.22,70.32.47,75.49.47,8.13,0,10.53,7.14,10.91,8.51H326.79v0l-.22-.05-.37.1H265.83l-.2,0h-.79v0Z"/>
                    <polygon id="Right_Front_Door" data-name="Right Front Door"
                             className={`panel-dark${handlePanelStriping(34)}`}
                             onClick={() => handlePanelClick(34)}
                             points="265.83 232.06 341.42 231.8 330.02 275.94 265.83 275.94 265.83 232.06"/>
                    <path id="Right_Front_Fender" data-name="Right Front Fender"
                          className={`panel-dark${handlePanelStriping(31)}`}
                          onClick={() => handlePanelClick(31)}
                          d="M342.44,231.8c39.6,0,69.55,1.37,82.18,3.68,4.46.82,4.8,5.84,4.8,7.36,0,.27,0,.44,0,.44L428,257.39a21.28,21.28,0,0,1-21.22,19.15h-7.64c-.09-14.74-10.37-30.34-29.34-30.34-17.75,0-30.88,12.5-31.3,29.74H331Z"/>
                    <g id="Right_Rear_Qtr_Panel" data-name="Right Rear Qtr Panel" onClick={() => handlePanelClick(41)}>
                        <path className={`panel-dark${handlePanelStriping(41)}`}
                              d="M149.13,232.3l35.39-.07c.34,9.73,2.5,19,4.58,27.92,1.28,5.52,2.49,10.73,3.23,15.79h-3.54c-.34-7.52-5.14-20.59-17.09-26.32a35.45,35.45,0,0,0-13.84-3c-10.33,0-29.84,6.25-30,29.87l-3.33,0c-16.5.14-16.5.14-24.46-34.86-.64-2.81-1.33-5.86-2.09-9.15Z"/>
                    </g>
                    <g id="Right_Rear_Door" data-name="Right Rear Door" onClick={() => handlePanelClick(38)}>
                        <path className={`panel-dark${handlePanelStriping(38)}`}
                              d="M264.84,232.07v43.87H193.32c-.85-5.94-2.21-11.87-3.53-17.61-1.95-8.52-4-17.32-4.28-26.1Z"/>
                    </g>
                    <path id="Right_Rocker" data-name="Right Rocker"
                          className={`panel-dark${handlePanelStriping(37)}`}
                          onClick={() => handlePanelClick(37)}
                          d="M264.84,276.93l.2,0h.79v0h60.41l.51.14,0-.14H338.4c-.36,1.3-2.74,8.51-10.91,8.51-5.17,0-42.53.25-75.49.47-26.21.18-48.85.33-51.35.33-8.34,0-11.29-7.84-11.77-9.31Z"/>
                    <path id="Roof" className={`panel-dark${handlePanelStriping(6)}`}
                          onClick={() => handlePanelClick(6)}
                          d="M133.51,152.25c0-21.91,7.34-34.27,8.31-35.23a5.31,5.31,0,0,1,3.77-1.57s28.75,2.6,45.41,2.6c12.59,0,102.32-2.6,102.32-2.6A5.32,5.32,0,0,1,297.1,117c1,1,6.52,13.32,6.52,35.23s-5.56,34.26-6.52,35.22a5.32,5.32,0,0,1-3.78,1.57s-86.57-2.6-102.32-2.6-45.41,2.6-45.41,2.6a5.31,5.31,0,0,1-3.77-1.57C140.85,186.51,133.51,174.15,133.51,152.25Z"/>
                    <path id="Lift_Gate" data-name="Lift Gate"
                          className={`panel-dark${handlePanelStriping(25)}`}
                          onClick={() => handlePanelClick(25)}
                          d="M139.1,114.8l-31.4-25.6c-6.5,0.2-10.6,0.4-10.8,0.6C95.5,91.5,85,109.2,85,151.3   c0,42,10.5,59.8,11.9,61.4c0.2,0.2,4.7,0.4,11.8,0.6l30.4-23.7C123.2,148.6,139.1,114.8,139.1,114.8z M100.7,97.4   c3.8-3.8,9-2.4,9-2.4s-7.7,21.9-13.8,21.9C95.8,116.9,96.9,101.1,100.7,97.4z M101,205.3c-3.8-3.8-4.8-19.5-4.8-19.5   c6.1,0,13.8,21.9,13.8,21.9S104.7,209,101,205.3z M134.8,188.7l-22.7,11.1c-1.9,0-11.6-22.1-11.6-48.5c0-26.4,9.8-47.6,11.6-47.5   l22.7,11.2c0,0-7.6,9.5-7.6,36.4C127.2,178.1,134.8,188.7,134.8,188.7z"/>
                </g>
            </g>
            {isSuggestedDamagesPresent() &&
                <>
                    <rect id="exterior-legend" x="0" y="350" width="5%" height="5%" fill="url(#suggested-legend)"></rect>
                    <text id="exterior-legend-text" x="23" y="366" fill="var(--charcoal)">Suggested Damage</text>
                </>
            }
        </svg>
    )
};

const matchDispatchToProps = {
    handlePanelClick
};

function mapStateToProps({globalDisplay}) {
    const {selectedFlatCarPanel} = globalDisplay;
    return {selectedFlatCarPanel};
}

export default connect(mapStateToProps, matchDispatchToProps)(SUVExterior)