import {
    LOAD_WORK_ORDER_SUCCESS,
    RE_DECODE_VIN,
    SET_DESIGNATED_DESCRIPTION,
    SET_DESIGNATED_DESCRIPTION_FROM_VCR,
    UPDATE_BLOCK_TYPE,
    UPDATE_BODY_STYLE,
    UPDATE_COUNTRY,
    UPDATE_CYLINDER_COUNT,
    UPDATE_DISPLACEMENT,
    UPDATE_DRIVETRAIN,
    UPDATE_FUEL_TYPE,
    UPDATE_INDUCTION,
    UPDATE_INSTALLED_COLOR,
    UPDATE_MAKE,
    UPDATE_MODEL,
    UPDATE_ROOF_TYPE,
    UPDATE_SEAT_TRIM,
    UPDATE_SUB_SERIES,
    UPDATE_TRANSMISSION,
    UPDATE_TRIM,
    UPDATE_WHEELS,
    UPDATE_YEAR
} from "../actions/dispatchTypes";
import {
    EXTERIOR, HARDTOP,
    INSPECTOR_PROVIDED,
    INTERIOR,
    NOT_SPECIFIED,
    ROOF_IDS,
    ROOF_TYPES,
    WHEEL_IDS,
    WHEEL_TYPES
} from "../utils/constants";
import update from "immutability-helper";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";

export default function designatedDescriptionReducer(state = null, action) {
    switch (action.type) {
        case LOAD_WORK_ORDER_SUCCESS:
            return getDesignatedDescription(action.payload);
        case UPDATE_INSTALLED_COLOR:
            return setInstalledColor(state, action.payload.color, action.payload.colorType);
        case UPDATE_SEAT_TRIM:
            return setSeatTrim(state, action.payload);
        case SET_DESIGNATED_DESCRIPTION:
            return setDesignatedDescription(action.payload);
        case SET_DESIGNATED_DESCRIPTION_FROM_VCR:
            return getDesignatedDescriptionFromVcr(action.payload);
        case UPDATE_WHEELS:
            return updateWheels(state, action.payload);
        case UPDATE_ROOF_TYPE:
            return updateRoofType(state, action.payload);
        case UPDATE_YEAR:
            return updateYear(state, action.payload);
        case UPDATE_MAKE:
            return updateMake(state, action.payload);
        case UPDATE_MODEL:
            return updateModel(state, action.payload);
        case UPDATE_TRIM:
            return updateTrim(state, action.payload);
        case UPDATE_FUEL_TYPE:
            return updateFuelType(state, action.payload);
        case UPDATE_CYLINDER_COUNT:
            return updateCylinderCount(state, action.payload);
        case UPDATE_BLOCK_TYPE:
            return updateBlockType(state, action.payload);
        case UPDATE_DISPLACEMENT:
            return updateDisplacement(state, action.payload);
        case UPDATE_INDUCTION:
            return updateInduction(state, action.payload);
        case UPDATE_COUNTRY:
            return updateCountry(state, action.payload);
        case UPDATE_BODY_STYLE:
            return updateBodyStyle(state, action.payload);
        case RE_DECODE_VIN:
            return getDesignatedDescription(action.payload);
        case UPDATE_TRANSMISSION:
            return updateTransmission(state, action.payload);
        case UPDATE_DRIVETRAIN:
            return updateDrivetrain(state, action.payload);
        case UPDATE_SUB_SERIES:
            return updateSubSeries(state, action.payload);
        default:
            return state;
    }
}

export const getDesignatedDescription = (payload) => {
    const isTrustedDesignation = isTrusted(payload);
    if (!payload.designatedDescriptionResponse) {
        return null;
    } else if (payload.designatedDescriptionResponse.catalogVehicles && payload.designatedDescriptionResponse.catalogVehicles.length === 1) {
        return buildDesignatedDescription(payload, isTrustedDesignation);
    } else {
        const commonVehicleInfo = payload.designatedDescriptionResponse.vcrResponse;
        return {
            year: commonVehicleInfo.year,
            make: commonVehicleInfo.make,
            model: commonVehicleInfo.model,
            vin: commonVehicleInfo.vin
        };
    }
}

function populateColors(designatedDescription) {
    let colors = !!designatedDescription?.colors ? designatedDescription.colors : {interior: [], exterior: []};
    let interiorColor = colors?.interior?.find(color => color.installed === true);
    if (!!interiorColor) {
        interiorColor.isDesignated = true;
        interiorColor.installed = true;
        interiorColor.installedReason = INSPECTOR_PROVIDED;
        colors.interior = [interiorColor]
    }
    let exteriorColor = designatedDescription.colors?.exterior?.find(color => color.installed === true && color.isPrimary !== false);
    if (!!exteriorColor) {
        exteriorColor.isDesignated = true;
        exteriorColor.installed = true;
        exteriorColor.installedReason = INSPECTOR_PROVIDED;
        colors.exterior = [exteriorColor]
    }
}

export const getDesignatedDescriptionFromVcr = (payload) => {
    if (payload === null) {
        return null;
    } else {
        if (payload.catalogVehicles) {
            payload.catalogVehicles.forEach(vehicle => {
                if (!!vehicle.colors?.interior) {
                    vehicle.colors.interior.map(color => {
                        if (!color.normalizedName) {
                            color.normalizedName = NOT_SPECIFIED
                        }
                        return color;
                    })
                }
            })
        }
    }
    let designatedDescription = cloneDeep(payload.catalogVehicles[0])
    populateColors(designatedDescription);
    designatedDescription.vin = payload.vin;
    designatedDescription.title = payload.title;
    return designatedDescription;
}

const isTrusted = (payload) => {
    return payload.designatedDescriptionResponse !== undefined
        && payload.designatedDescriptionResponse !== null
        && !!payload.designatedDescriptionResponse.fromTrustedSource;
}

const buildDesignatedDescription = (payload, isTrustedDesignation) => {
    let designatedDescription = cloneDeep(payload.designatedDescriptionResponse.catalogVehicles[0])
    let interiorColor;
    let exteriorColor;
    if (isTrustedDesignation) {
        interiorColor = designatedDescription.colors?.interior?.find(color => !!color.isDesignated);
        if (!!interiorColor) {
            if (interiorColor.materialType?.includes('Not Specified')) {
                interiorColor.materialType = null;
            }
            interiorColor.installed = true;
        } else {
            interiorColor = designatedDescription.colors?.interior?.find(color => color.installed === true);
            if (!!interiorColor) {
                interiorColor.installedReason = INSPECTOR_PROVIDED
            }
        }
        !!interiorColor ? designatedDescription.colors.interior = [interiorColor] : designatedDescription.colors.interior = [];

        exteriorColor = designatedDescription.colors?.exterior?.find(color => !!color.isDesignated && color.isPrimary !== false);
        if (!!exteriorColor) {
            exteriorColor.installed = true;
        } else {
            exteriorColor = designatedDescription.colors?.exterior?.find(color => color.installed === true);
            if (!!exteriorColor) {
                exteriorColor.installedReason = INSPECTOR_PROVIDED
            }
        }
        !!exteriorColor ? designatedDescription.colors.exterior = [exteriorColor] : designatedDescription.colors.exterior = [];
    } else {
        populateColors(designatedDescription);
    }

    designatedDescription.vin = payload.designatedDescriptionResponse.vin;
    designatedDescription.title = payload.designatedDescriptionResponse.title;
    return designatedDescription;
}


const setSeatTrim = (state, payload) => {
    let updatedColor = Object.assign(payload.interiorColor, {materialType: [payload.seatTrim]})
    return update(state, {
        colors: {
            interior: {
                $set: [
                    updatedColor,
                ]
            },
        },
    });
}

const setInstalledColor = (state, color, type) => {
    if (!state.colors) {
        state.colors = {}
    }
    if (type === INTERIOR) {
        if (!state.colors.exterior) {
            state.colors.exterior = []
        }
        if (state.colors?.interior) {
            return update(state, {
                colors: {
                    interior: {
                        $set: [
                            color,
                        ]
                    },
                },
            });
        } else {
            return update(state, {
                $merge: {
                    colors: {
                        interior: [color],
                        exterior: state.colors.exterior
                    }
                }
            });
        }
    } else {
        if (!state.colors.interior) {
            state.colors.interior = []
        }
        if (state.colors?.exterior) {
            return update(state, {
                colors: {
                    exterior: {
                        $set: [
                            color,
                        ]
                    },
                },
            });
        } else {
            return update(state, {
                $merge: {
                    colors: {
                        exterior: [color],
                        interior: state.colors.interior
                    }
                }
            });
        }
    }
}

const setDesignatedDescription = (payload) => {
    return {
        ...payload.designatedDescription,
        vin: payload.vin
    }
}

const updateRoofType = (state, roofId) => {
    if (!state) {
        state = {}
    }
    roofId = parseInt(roofId);
    let updatedInstalledEquipment = cloneDeep(state.installedEquipment) || [];
    const roofToRemove = updatedInstalledEquipment.find(
        e => e.installedReason === INSPECTOR_PROVIDED && e.generics?.some(g => ROOF_IDS.includes(g.id) || g.id === HARDTOP.id))
    if (roofToRemove) {
        updatedInstalledEquipment = updatedInstalledEquipment.filter(e => !isEqual(e, roofToRemove));
    }
    const roofName = ROOF_TYPES.find(roof => roof.id === roofId)?.name || HARDTOP.name;

    return update(state, {
        installedEquipment: {
            $set: [
                ...updatedInstalledEquipment,
                {
                    classification: EXTERIOR.toUpperCase(),
                    generics: [
                        {
                            id: roofId,
                            name: roofName
                        }
                    ],
                    installedReason: INSPECTOR_PROVIDED,
                    primaryDescription: roofName
                }
            ]
        }

    });
}

const updateWheels = (state, wheelCode) => {
    if (!state) {
        state = {}
    }
    wheelCode = parseInt(wheelCode);

    let updatedInstalledEquipment = cloneDeep(state.installedEquipment) || [];
    const wheelToRemove = updatedInstalledEquipment.find(e => e.classification === 'EXTERIOR' && e.installedReason.toLowerCase() !== 'standard' && e.generics?.some(g => WHEEL_IDS.includes(g.id)))

    if (wheelToRemove) {
        updatedInstalledEquipment = updatedInstalledEquipment.filter(e => !isEqual(e, wheelToRemove));
    }
    const wheelName = WHEEL_TYPES.find(wheel => wheel.id === wheelCode)?.name;

    return update(state, {
        installedEquipment: {
            $set: [
                ...updatedInstalledEquipment,
                {
                    classification: "EXTERIOR",
                    generics: [
                        {
                            id: wheelCode,
                            name: wheelName
                        }
                    ],
                    installedReason: "Inspector Provided",
                    primaryDescription: wheelName
                }
            ]
        }

    });
}

const updateYear = (state, year) => {
    if (!state) {
        state = {}
    }
    return update(state, {
        $merge: {year}
    });
}

const updateMake = (state, payload) => {
    return update(state, {
        $merge: {make: payload.name}
    });
}

const updateModel = (state, payload) => {
    return update(state, {
        $merge: {
            model: {
                normalizedName: payload.name
            }
        }
    });
}

const updateTrim = (state, trim) => {
    return update(state, {
        $merge: {
            trim: {
                normalizedName: trim
            }
        }
    });
}

const updateFuelType = (state, fuelType) => {
    const engine = state.powertrain?.engine || {}
    return update(state, {
        $merge: {
            powertrain: {
                engine: {
                    ...engine,
                    fuelCategory: fuelType
                }
            }
        }
    });
}

const updateCylinderCount = (state, payload) => {
    const engine = state.powertrain?.engine || {}
    return update(state, {
        $merge: {
            powertrain: {
                engine: {
                    ...engine,
                    cylinderCount: payload.name
                }
            }
        }
    });
}

const updateBlockType = (state, blockType) => {
    const engine = state.powertrain?.engine || {}
    return update(state, {
        $merge: {
            powertrain: {
                engine: {
                    ...engine,
                    blockType
                }
            }
        }
    });
}

const updateDisplacement = (state, payload) => {
    const engine = state.powertrain?.engine || {}
    const displacement = state.powertrain?.engine?.displacement || {}
    return update(state, {
        $merge: {
            powertrain: {
                engine: {
                    ...engine,
                    displacement: {
                        ...displacement,
                        amount: payload.name
                    }
                }
            }
        }
    });
}

const updateInduction = (state, induction) => {
    const engine = state.powertrain?.engine || {}
    return update(state, {
        $merge: {
            powertrain: {
                ...state.powertrain,
                engine: {
                    ...engine,
                    aspiration: induction
                }
            }
        }
    });
}

const updateCountry = (state, payload) => {
    if (!state) {
        state = {}
    }
    return update(state, {
        $merge: {
            country: {
                code: payload.code,
                name: payload.name
            }
        }
    });
}

const updateBodyStyle = (state, payload) => {
    if (!state) {
        state = {}
    }
    return update(state, {
        $merge: {
            bodyDescription: {
                primaryBodyStyle: payload.name,
                vehicleType: payload.vehicleSubType
            }
        }
    });
}

const updateTransmission = (state, transmission) => {
    if (!state) {
        state = {}
    }
    return update(state, {
        powertrain: {
            $set: {
                ...state.powertrain,
                transmission: {
                    type: transmission
                }
            }
        }
    });
}

const updateDrivetrain = (state, drivetrain) => {
    if (!state) {
        state = {}
    }
    return update(state, {
        powertrain: {
            $set: {
                ...state.powertrain,
                drivetrain: {
                    type: drivetrain
                }
            }
        }
    });
}

const updateSubSeries = (state, subSeries) => {
    return update(state, {
        $merge: {
            thirdPartyIdentifiers: {
                ...state.thirdPartyIdentifiers,
                manheim: [
                    subSeries
                ]
            }
        }
    });
}