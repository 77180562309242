import {
    ADD_AUTOMATED_DAMAGE,
    DELETE_AUTOMATED_DAMAGE,
    LOAD_WORK_ORDER_SUCCESS,
    UNLOAD_WORK_ORDER
} from "../actions/dispatchTypes";
import {
    BMW_GROUP_CODES,
    MBC_GROUP_CODES
} from "../utils/constants";
import {
    AUTOMATICALLY_CREATED_DAMAGES,
    SERVICE_REQUIRED_DAMAGE
} from "../utils/constantsDamages";
import {calculateDamageKey} from "./conditionReducer";

export default function automatedDamagesReducer(state = [], action) {
    switch (action.type) {
        case LOAD_WORK_ORDER_SUCCESS:
            return getAutomatedDamagesFromCondition(state, action.payload);
        case UNLOAD_WORK_ORDER:
            return [];
        case ADD_AUTOMATED_DAMAGE:
            return addAutomatedDamageToList(state, action.payload);
        case DELETE_AUTOMATED_DAMAGE:
            return removeAutomatedDamageFromList(state, action.payload);
        default:
            return state;
    }
}

function addAutomatedDamageToList(state, damage) {
    damage.damageKey = calculateDamageKey(damage)
    const updatedState = [...state];
    updatedState.push(damage);
    return updatedState;
}

function removeAutomatedDamageFromList(state, damage) {
    return state.filter(orgDamage => orgDamage.item !== damage.item)
}

function getAutomatedDamagesFromCondition(state, payload) {
    let completeAutoDamageList = [];
    if(!payload.condition || !payload.condition.damages) {
        return state
    } else {
        const autoDamageList = AUTOMATICALLY_CREATED_DAMAGES.concat(payload.damageRules.damages);
        if (BMW_GROUP_CODES.includes(payload.account.groupCode) || MBC_GROUP_CODES.includes(payload.account.groupCode)) {
            autoDamageList.push(SERVICE_REQUIRED_DAMAGE)
        }
        autoDamageList.forEach(autoDamage => {
            const autoDamageKey = `L1-${autoDamage.itemCode}-${autoDamage.subItemCode}-${autoDamage.damageCode}`;
            payload.condition.damages.forEach(conditionDamage => {
                if(conditionDamage.damageKey.startsWith(autoDamageKey)) {
                    completeAutoDamageList.push(conditionDamage)
                }
            })
        })
    }
    return completeAutoDamageList
}