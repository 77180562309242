import React, {useMemo} from "react";
import {connect} from "react-redux";
import Button from "@prism/button";
import {addSuggestedDamage} from "../../../actions/SuggestedDamageActions";
import {addDamage} from "../../../actions/conditionActions";
import {FYU_DAMAGE_ANALYSIS} from "../../../utils/constants";
import {showDamageSuccessMessage} from "../../../actions/globalDisplayActions";
import {addSuggestedDamageImage} from "../../../actions/damageImageActions";
import Api from "../../Api";
import {clearSelectedFlatCarPanel} from "../../../actions/flatCarActions";

const AddSuggestedDamageButton = (props) => {
    const {
        account,
        addDamage,
        addSuggestedDamage,
        addSuggestedDamageImage,
        consignment,
        suggestedDamage,
        showDamageSuccessMessage,
        workOrderInfo,
        clearSelectedFlatCarPanel,
        panelSuggestedDamages,
        setApiLoading
    } = props;

    async function getDefaultChargeable() {
        const abortController = new AbortController();
        const signal = abortController.signal;

        return Api.getChargeableOptions({
            itemCode: suggestedDamage.artCodes.itemCode || '',
            subItemCode: suggestedDamage.artCodes.subItemCode || '',
            damageCode: suggestedDamage.artCodes.damageCode || '',
            severityCode: suggestedDamage.artCodes.severityCode || '',
            groupCode: account.groupCode || '',
            category: consignment.categoryCode || '',
            signal: signal,
            listOptions: false,
        });
    }

    async function getDefaultAction() {
        const abortController = new AbortController();
        const signal = abortController.signal;

        return Api.getActions({
            itemCode: suggestedDamage.artCodes.itemCode || '',
            subItemCode: suggestedDamage.artCodes.subItemCode || '',
            damageCode: suggestedDamage.artCodes.damageCode || '',
            severityCode: suggestedDamage.artCodes.severityCode || '',
            groupCode: account.groupCode || '',
            category: consignment.categoryCode || '',
            auctionCode: workOrderInfo.auctionCode || '',
            signal: signal,
            listOptions: false,
        });
    }

    function getDamageKey() {
        return suggestedDamage.newDamageKey ? suggestedDamage.newDamageKey : suggestedDamage.artCodes.damageKey;
    }

    const buildDamageObject = async () => {
        // Default chargeable values if no default is returned from api
        let chargeable = 'Y';
        let chargeableDescription = 'Chargeable';
        let action = ''
        let actionCode = ''

        await getDefaultChargeable().then(response => {
            if (response.code && response.description) {
                chargeable = response.code;
                chargeableDescription = response.description;
            }
        });

        await getDefaultAction().then(response => {
            const defaultAction = response.items?.find(i => i.action.hasOwnProperty('default'));
            if (defaultAction) {
                action = defaultAction.action.description;
                actionCode = defaultAction.action.code;
            }
        });

        return {
            item: suggestedDamage.artCodes.item,
            itemCode: suggestedDamage.artCodes.itemCode,
            subItemCode: suggestedDamage.artCodes.subItemCode,
            damage: suggestedDamage.artCodes.damage,
            damageCode: suggestedDamage.artCodes.damageCode,
            severity: suggestedDamage.artCodes.severity,
            severityCode: suggestedDamage.artCodes.severityCode,
            action: action,
            actionCode: actionCode,
            locationCode: suggestedDamage.artCodes.locationCode,
            damageKey: getDamageKey(),
            hasImage: suggestedDamage.path,
            sourcePlatform: FYU_DAMAGE_ANALYSIS,
            chargeable: chargeable,
            chargeableDescription: chargeableDescription,
        };
    };
    const handleClick = async () => {
        setApiLoading(true);
        addSuggestedDamage({...suggestedDamage, isEdited: props.isEdited});
        addDamage(await buildDamageObject(suggestedDamage));

        if (!!suggestedDamage.path) {
            addSuggestedDamageImage(getDamageKey(), suggestedDamage.path)
        }
        showDamageSuccessMessage();
        if(!panelSuggestedDamages()) clearSelectedFlatCarPanel();
        setApiLoading(false);
    }

    const isDisabled = useMemo(() => {
        return !suggestedDamage.artCodes.damage ||
            !suggestedDamage.artCodes.damageCode ||
            !suggestedDamage.artCodes.severity ||
            !suggestedDamage.artCodes.severityCode;
    }, [suggestedDamage]);

    return (
        <Button id={'add-suggested-' + suggestedDamage.artCodes.damageKey}
                onClick={handleClick}
                disabled={isDisabled}
                className={"mx-1 my-0"}
                color="secondary">
            <i className={"icon prism-icon-checkmark p-1 " + (isDisabled ? 'text-light-gray' : 'text-white')}/>
        </Button>
    );
};

const matchDispatchToProps = {
    addDamage,
    addSuggestedDamage,
    addSuggestedDamageImage,
    showDamageSuccessMessage,
    clearSelectedFlatCarPanel
};

function mapStateToProps({account, condition, consignment, workOrderInfo}) {
    return {account, condition, consignment, workOrderInfo};
}

export default connect(mapStateToProps, matchDispatchToProps)(AddSuggestedDamageButton);
