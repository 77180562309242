import {APPROVALS_API_URL} from "../config/env";
import csrf from "../csrf";

class Api {

    static notifyAjaxRequestStarted = () => {
        // if (typeof (NativeApp) !== 'undefined') {
        //     NativeApp.startLoadingIndicator();
        // }
    };

    static notifyAjaxRequestFinished = () => {
        // if (typeof (NativeApp) !== 'undefined') {
        //     NativeApp.stopLoadingIndicator();
        // }
    };

    static clearSession = async () => {
        return await this._fetch(`${APPROVALS_API_URL}/unified/cleanup?application=smart_inspect`, {
            method: 'GET'
        });
    };

    // Does not increase pending api calls because scan submitter has its own loading spinner with cancel button
    static fetchWorkOrder = async (params) => {
        let uri = `${APPROVALS_API_URL}/smartInspect/auction/${params.locationCode}/workorder/${params.workOrderNumber}`;
        const response = await this._fetch(uri, {
            method: 'GET',
            headers: params.headers,
            signal: params.signal
        });
        if (response.ok) {
            return await response.json();
        } else if (params.signal.aborted) {
            return "aborted by user";
        } else {
            return response;
        }
    };

    // Does not increase pending api calls because scan submitter has its own loading spinner with cancel button
    static fetchWorkOrderByInspectionType = async (params) => {
        let uri = `${APPROVALS_API_URL}/smartInspect/auction/${params.locationCode}/workorder/${params.workOrderNumber}?inspectionType=${params.inspectionType}`;
        const response = await this._fetch(uri, {
            method: 'GET',
            headers: params.headers,
            signal: params.signal
        });
        if (response.ok) {
            return await response.json();
        } else if (params.signal.aborted) {
            return "aborted by user";
        } else {
            return response;
        }
    };

    static getAuctionLocations = async () => {
        const response = await this._fetch(`${APPROVALS_API_URL}/smartInspect/locations?operatedBy=manheim&locationType=auction&locationActive=true`, {
            method: 'GET'
        });
        return response.ok ? response.json() : [];
    };

    static getUserDetails = async (params) => {
        const response = await this._fetch(`${APPROVALS_API_URL}/smartInspect/oauth2UserDetails?application=smart_inspect`, {
            method: 'GET'
        });
        return response.ok ? response.json() : response;
    };

    static getDamages = async (params) => {
        const categoryCode = params.category || '';
        let uri = `${APPROVALS_API_URL}/smartInspect/art/itemDamages/item/${params.itemCode}${params.subItemCode}?customer=${params.groupCode}&category=${categoryCode}`;

        const response = await this._fetch(uri, {credentials: 'same-origin', method: 'GET', signal: params.signal});
        if (response.ok) {
            return await response.json();
        } else {
            return response;
        }

    };

    static getSeverities = async (params) => {
        const categoryCode = params.category || '';
        let uri = `${APPROVALS_API_URL}/smartInspect/art/itemDamageSeverities/item/${params.itemCode}${params.subItemCode}/damage/${params.damageCode}?customer=${params.groupCode}&category=${categoryCode}`;

        const response = await this._fetch(uri, {credentials: 'same-origin', method: 'GET', signal: params.signal});
        if (response.ok) {
            return await response.json();
        } else {
            return response;
        }
    };

    static getActions = async (params) => {
        const categoryCode = params.category || '';
        let uri = `${APPROVALS_API_URL}/smartInspect/art/itemDamageSeverityActions/item/${params.itemCode}${params.subItemCode}/damage/${params.damageCode}/severity/${params.severityCode}?customer=${params.groupCode}&category=${categoryCode}&auctionCode=${params.auctionCode}&listOptions=${params.listOptions}`;

        const response = await this._fetch(uri, {credentials: 'same-origin', method: 'GET', signal: params.signal});
        if (response.ok) {
            return await response.json();
        } else {
            return response;
        }
    };

    static getChargeableOptions = async (params) => {
        const categoryCode = params.category || '';

        let uri = `${APPROVALS_API_URL}/smartInspect/art/chargeabilities/item/${params.itemCode}${params.subItemCode}/damage/${params.damageCode}/severity/${params.severityCode}?customer=${params.groupCode}&category=${categoryCode}&listOptions=${params.listOptions}`;

        const response = await this._fetch(uri, {credentials: 'same-origin', method: 'GET', signal: params.signal});
        if (response.ok) {
            return await response.json();
        } else {
            return response;
        }
    };

    static getItemsForPanel = async (params) => {
        const uri = `${APPROVALS_API_URL}/smartInspect/art/items/search?panel_id=${params.panelId}&flat_car_type_id=${params.flatCarTypeId}`;

        const response = await this._fetch(uri, {credentials: 'same-origin', method: 'GET', signal: params.signal});
        if (response.ok) {
            return await response.json();
        } else {
            return response;
        }
    };

    static submitStagedInspect = async (payload) => {

        let headers = csrf.getCSRFHeaders();
        headers['Content-Type'] = 'application/json';

        const response = await this._fetch(`${APPROVALS_API_URL}/smartInspect`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(payload)
        });
        if (response.ok) {
            return await response.json();
        } else {
            return await response.text();
        }
    };

    static getAnnouncementsDictionary = async () => {
        const uri = `${APPROVALS_API_URL}/smartInspect/condition/announcements/dictionary`;

        const response = await this._fetch(uri, {method: 'GET'});
        if (response.ok) {
            return await response.json();
        }
    };

    static getVehicleNotes = async (params) => {
        Api.notifyAjaxRequestStarted();
        let uri = `${APPROVALS_API_URL}/smartInspect/notes/vehicle/auctionId/${params.locationCode}/workOrder/${params.workOrderNumber}`;
        console.debug("getting vehicle notes");
        const response = await this._fetch(uri, {
            method: 'GET',
            signal: params.signal
        });
        if (!!response) {
            const logName = `response-${params.locationCode}-${params.workOrderNumber}`
            console.debug(`Api->getVehicleNotes->${logName}->status: ${response.status}`)
        } else {
            const logLine = `There was no notes response for: ${params.locationCode}-${params.workOrderNumber}`
            console.debug(`Api->getVehicleNotes->${logLine}`)
        }
        return response.ok ? response.json() : response;
    };

    static getWorkList = async (params) => {
        let uri = `${APPROVALS_API_URL}/smartInspect/inspections/staged/?vehicle.userId=${params.userId}&limit=50`;

        const response = await this._fetch(uri, {
            method: 'GET'
        });
        return response.ok ? response.json() : response;
    };

    static getWorkOrderLatestStatus = async (params) => {
        let uri = `${APPROVALS_API_URL}/smartInspect/inspections/staged/?vehicle.consignment.href=${params.consignment.href}&limit=1`;

        const response = await this._fetch(uri, {
            method: 'GET'
        });
        return response.ok ? response.json() : response;
    };

    static fetchEstimate = async (request) => {

        return this._fetch(`${APPROVALS_API_URL}/unified/estimate`, request)
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    if (response.status !== 404) {
                        throw new Error("Error calling the pricing estimate")
                    }
                }
            })
            .then(data => {
                console.debug("fetchEstimate response data", data);
                return data;
            })
            .catch(e => {
                console.log(e);
                throw new Error("Error calling the pricing estimate")
            });
    };

    static fetchPricing = async (request) => {
        return this._fetch(`${APPROVALS_API_URL}/unified/pricing`, request)
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error("Error fetching pricing")
                }
            })
            .catch(e => {
                console.log(e);
                throw new Error("Error fetching pricing")
            });
    }

    static getDamageEstimatorQuestions = async (params) => {
        let uri = `${APPROVALS_API_URL}/unified/damageEstimator/questions`;

        const response = await this._fetch(uri, {
            method: 'POST',
            headers: params.headers,
            body: JSON.stringify(
                {
                    itemCode: params.itemCode,
                    subItemCode: params.subItemCode,
                    damageCode: params.damageCode,
                    severityCode: params.severityCode,
                    actionCode: params.actionCode,
                    chargeable: params.chargeable,
                    manufacturerCode: params.manufactureCode,
                    directTurnIn: params.directTurnIn,
                    categoryCode: params.categoryCode
                }
            )
        });
        if (response.ok) {
            return await response.json();
        } else {
            return response;
        }
    };

    static calculateRepairHours = async (params) => {
        this.notifyAjaxRequestStarted();
        const uri = `${APPROVALS_API_URL}/unified/damageEstimator/calculateRepairHours`;
        let headers = csrf.getCSRFHeaders();
        headers['Content-Type'] = 'application/json; charset=utf-8';
        const response = await this._fetch(uri, {
            ...params,
            method: 'POST',
            headers: {...headers, ...params.headers}
        });
        if (response.ok) {
            this.notifyAjaxRequestFinished();
            return await response.json();
        } else {
            this.notifyAjaxRequestFinished();
            return response;
        }
    };

    static getCatalogVehicleForSelectedTrim = async (params) => {
        const uri = `${APPROVALS_API_URL}/smartInspect/vehicle-catalog-resolver`;
        let headers = csrf.getCSRFHeaders();
        headers['Content-Type'] = 'application/json; charset=utf-8';
        const response = await this._fetch(uri, {
            body: JSON.stringify({
                vin: params.vin,
                sessionId: params.sessionId,
                userSelectedVehicleId: params.userSelectedTrimId,
                reDecodeVin: params.reDecodeVin,
                includeQuestions: params.includeQuestions,
                questions: params.questions,
                vehicleTrims: params.vehicleTrims
            }),
            method: 'POST',
            headers: {...headers, ...params.headers}
        });
        if (response.ok) {
            try {
                return await response.json();
            } catch (error) {
                console.error(error);
                return null;
            }
        } else {
            return response;
        }
    }

    static getYears = async () => {
        const uri = `${APPROVALS_API_URL}/smartInspect/search/taxonomy/years`;
        return await this.get(uri);
    }

    static getMakes = async (params) => {
        const uri = `${APPROVALS_API_URL}/smartInspect/search/taxonomy/makes?vehicleTypeIds=${params.vehicleTypeId}&vehicleSubTypeIds=${params.vehicleSubTypeId}`;
        return await this.get(uri);
    }

    static getModels = async (params) => {
        const uri = `${APPROVALS_API_URL}/smartInspect/search/taxonomy/models?makeIds=${params.makeId}&vehicleTypeIds=${params.vehicleTypeId}&vehicleSubTypeIds=${params.vehicleSubTypeId}`;
        return await this.get(uri);
    }

    static getTrims = async (params) => {
        const uri = `${APPROVALS_API_URL}/smartInspect/search/taxonomy/trims/id/${params.modelId}`;
        return await this.get(uri);
    }

    static getDrivetrains = async () => {
        const uri = `${APPROVALS_API_URL}/smartInspect/search/taxonomy/driveTrains`;
        return await this.get(uri);
    }

    static getTransmissions = async () => {
        const uri = `${APPROVALS_API_URL}/smartInspect/search/taxonomy/transmissions`;
        return await this.get(uri);
    }

    static getFuelTypes = async () => {
        const uri = `${APPROVALS_API_URL}/smartInspect/search/taxonomy/fuelTypes`;
        return await this.get(uri);
    }

    static getCylinderCounts = async () => {
        const uri = `${APPROVALS_API_URL}/smartInspect/search/taxonomy/cylinderCounts`;
        return await this.get(uri);
    }

    static getBlockTypes = async () => {
        const uri = `${APPROVALS_API_URL}/smartInspect/search/taxonomy/blockTypes`;
        return await this.get(uri);
    }

    static getDisplacements = async (params) => {
        const uri = `${APPROVALS_API_URL}/smartInspect/search/taxonomy/displacements?cylinderCountId=${params.cylinderCountId}`;
        return await this.get(uri);
    }

    static get = async (uri) => {
        let headers = csrf.getCSRFHeaders();
        headers['Content-Type'] = 'application/json; charset=utf-8';

        const response = await this._fetch(uri, {
            method: 'GET',
            headers: {...headers}
        });
        if (response.ok) {
            return await response.json();
        } else {
            return response;
        }
    }

    static _fetch = async (uri, params = {}) => {
        params.credentials = 'include';
        return fetch(uri, params);
    };

}

export default Api;